import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { observer } from "mobx-react-lite";
import { PaymentModal, PrimaryOfferModal, SecondaryOfferModal } from "./components";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useShowExtraOffers } from "./hooks";

export const ExtraOffers = observer(() => {
    const { userWebSession, updateUserWebSession } = useUserStore();
    const { amplitudeUserId } = useAppData();
    const [searchParams, setSearchParams] = useSearchParams();
    const extraOfferQuery = searchParams.get("extraOffer");
    const { showExtraOffers, currentStep } = useShowExtraOffers();

    const firstModalOpen = !currentStep && !!userWebSession;
    const secondModalOpen = !!(currentStep && currentStep === 1);
    const paymentModalOpen = !!(currentStep && currentStep === 2) || extraOfferQuery === "payment";

    useEffect(() => {
        if (!extraOfferQuery) return;

        const params = new URLSearchParams();
        params.set("usid", amplitudeUserId);
        setSearchParams(params);
    }, [extraOfferQuery]);

    const setStepPassed = (step: number) => {
        updateUserWebSession({
            session: { ...userWebSession?.session, extraOfferStepsShowed: step },
        });
    };

    const handlePrimaryModalClose = {
        onAccept: () => {
            setStepPassed(2);
        },
        onDecline: () => {
            setStepPassed(1);
        },
    };

    const handleSecondaryModalClose = {
        onAccept: () => {
            setStepPassed(2);
        },
        onDecline: () => {
            setStepPassed(3);
        },
    };

    const handlePaymentModalClose = () => {
        setStepPassed(3);
    };

    if (!showExtraOffers) return null;

    return (
        <>
            <PrimaryOfferModal open={firstModalOpen} {...handlePrimaryModalClose} />
            <SecondaryOfferModal open={secondModalOpen} {...handleSecondaryModalClose} />
            <PaymentModal open={paymentModalOpen} onClose={handlePaymentModalClose} />
        </>
    );
});
