import { forwardRef, useImperativeHandle } from "react";
import { useFormContext } from "react-hook-form";

export const IxopayFormSpy = forwardRef<any, Record<string, any>>((_props, ref) => {
    const { reset, setValue, setError, clearErrors } = useFormContext();

    useImperativeHandle(ref, () => ({
        setValue: (name: string, value: string) => {
            setValue(name, value);
        },
        setError: (
            name: string,
            error: {
                type: string;
                message: string;
            }
        ) => {
            setError(name, error);
        },
        clearErrors: (name: string | string[]) => {
            clearErrors(name);
        },
        resetForm: (initialValues?: any) => {
            reset(initialValues);
        },
    }));

    return null;
});

IxopayFormSpy.displayName = "IxopayFormSpy";
