import { useAppData, useUserStore } from "providers/RootStoreProvider";

export const useShowExtraOffers = () => {
    const { userSubscription, userWebSession } = useUserStore();
    const { abTestData, flowOuter } = useAppData();
    const currentStep = userWebSession?.session?.extraOfferStepsShowed;

    const isAbOffer = abTestData?.abOffer === "1";
    const showExtraOfferModals = flowOuter && userSubscription && !userSubscription.isPremium && isAbOffer && (!currentStep || currentStep < 3);

    return { showExtraOffers: showExtraOfferModals, currentStep };
};
