import { Stack, StackProps, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

interface Props extends StackProps {
    icon: ReactNode;
    text: string | ReactNode;
}

export const IconTextItem: FC<Props> = ({ icon, text, sx }) => {
    return (
        <Stack direction="row" alignItems="center" gap="1rem" mb="20px" sx={sx}>
            {icon}
            <Typography variant="smallRegular" sx={(theme) => ({ color: theme.palette.text.secondary })}>
                {text}
            </Typography>
        </Stack>
    );
};
