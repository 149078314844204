import { Box, CircularProgress, Stack, Typography, Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import { themeColors } from "constants/colors.const";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { themeFonts } from "mui-theme/themeFonts.const";
import { Languages } from "enums/languages.enum";

interface Props {
    label: string;
    value?: string;
    blurred?: boolean;
    defined?: boolean;
    sx?: CSSProperties;
    accentColor?: string;
}

export const SearchCard = ({ label, value, blurred, defined, accentColor = themeColors.brandColors.brandGreen, sx = {} }: Props) => {
    const { t, i18n } = useTranslation();
    const isArabicLanguage = i18n.language === Languages.sa;

    return (
        <Box
            sx={{
                height: "56px",
                width: "100%",
                borderRadius: "16px",
                background: themeColors.background.secondary,
                border: `2px solid ${accentColor}`,
                ...sx,
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ height: "100%", padding: "1rem" }}>
                <Typography variant="body1CaptionBold" textTransform="uppercase" color={themeColors.text.white} sx={{ lineHeight: "1.5" }}>
                    {label}
                </Typography>
                {value && (
                    <Typography variant="body1CaptionBold" color={accentColor} textTransform="capitalize" sx={{ ...(blurred && { filter: "blur(3px)" }) }}>
                        {value}
                    </Typography>
                )}
                {!value && !defined && <CircularProgress size="24px" sx={{ color: accentColor }} />}
                {defined && (
                    <Button
                        sx={{
                            backgroundColor: `${accentColor} !important`,
                            textTransform: "capitalize",
                            fontFamily: themeFonts.salara,
                            display: "flex",
                            gap: isArabicLanguage ? "10px" : "unset",
                        }}
                        variant="xs"
                        startIcon={<DoneIcon />}
                        disabled
                    >
                        {t("Defined")}
                    </Button>
                )}
            </Stack>
        </Box>
    );
};
