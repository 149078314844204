import { FC } from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { themeFonts } from "mui-theme/themeFonts.const";
import { themeColors } from "constants/colors.const";
import { formatterCurrencyUS } from "services/numberFormat";
import { useAppData } from "providers/RootStoreProvider";
import { AppRoutes } from "enums/routes.enum";
import { ConditionalWrapper } from "components/ConditionalWrapper";
import { CheckboxPrimary } from "components/shared/CheckboxPrimary";

import { PaymentIconsBlock } from "../../../shared/components";
import { paymentIconsListDefault } from "../../../shared/constants";
import { TypographyWithHighlight } from "components/shared";
import { getTomorrowDate } from "helpers/dateTime";

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    plan: any;
    ixopayFormShown: boolean;
    isVATUser: boolean;
    isUSAUser?: boolean;
}

export const DisclaimersFormBottomBlock: FC<Props> = ({ onChange, checked, plan, ixopayFormShown, isVATUser, isUSAUser }) => {
    const { flowOuter } = useAppData();

    return (
        <>
            <Stack>
                {ixopayFormShown && (
                    <Typography variant="smallRegular" color={themeColors.text.lighterGrey} sx={{ mt: "1.5rem", mb: ".75rem", lineHeight: "20px" }}>
                        {t("By clicking “Continue”, you accept our")}{" "}
                        <Link to={AppRoutes.TERMS_OF_USE} style={{ textDecoration: "underline" }}>
                            {t("Terms of Use_disclaimer")}
                        </Link>{" "}
                        {t("and")}{" "}
                        <Link to={AppRoutes.PRIVACY_POLICY} style={{ textDecoration: "underline" }}>
                            {t("Privacy Notice")}
                        </Link>
                    </Typography>
                )}
                <Typography variant="smallBold" sx={{ margin: ".75rem 0 1.5rem", whiteSpace: "pre-line" }} textAlign="center">
                    {t("Geomobile will appear on your billing statement")}
                </Typography>
                <PaymentIconsBlock iconsList={paymentIconsListDefault} />
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: "100%", mt: "1.5rem" }}>
                {!flowOuter && <CheckboxPrimary id="disclaimer-checkbox" checked={checked} onChange={onChange} />}

                <ConditionalWrapper
                    condition={!flowOuter}
                    wrapper={(children) => (
                        <label htmlFor="disclaimer-checkbox" style={{ lineHeight: "16px" }}>
                            {children}
                        </label>
                    )}
                >
                    <TypographyWithHighlight
                        variant="smallThin"
                        sx={{ lineHeight: "16px", fontFamily: themeFonts.salara, fontWeight: 400 }}
                        text={t("legal_payment_disclaimer", {
                            price: formatterCurrencyUS.format(plan?.price),
                            trialPrice: formatterCurrencyUS.format(plan?.trialPriceVat),
                            subscriptionPaymentDate: getTomorrowDate(isUSAUser),
                            vatMessage: isVATUser ? `${t("Payment_form_descr_p6")}.` : "",
                        })}
                        highlight={
                            <Link to={`${AppRoutes.TERMS_OF_USE}#subscriptions-and-charges`} style={{ textDecoration: "underline" }}>
                                {t("Subscription Terms")}
                            </Link>
                        }
                    />
                </ConditionalWrapper>
            </Stack>
        </>
    );
};
