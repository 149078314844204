import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Box, Stack, styled, Typography } from "@mui/material";

import { FakePaywallMap, FormWithDiscountAndDisclaimer, IconTextItem } from "components/Payment/shared/components";
import { IxopayFormProps } from "../../shared/types";
import { useAppData } from "providers/RootStoreProvider";
import { themeColors } from "constants/colors.const";
import { useTranslation } from "react-i18next";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { LoadingButton } from "components/LoadingButton";

import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";
import { benefits } from "components/Payment/shared/constants";

const StyledPaywallContainer = styled(Box)(({ theme }) => ({
    margin: "8.5rem auto 0",
    width: "866px",
    background: theme.palette.background.grey,
    borderRadius: "1.5rem",
    padding: "1rem 1rem 3rem",
    [theme.breakpoints.down("sm")]: {
        width: "100vw",
        margin: "65px 0 -11vh -20px",
        padding: 0,
        borderRadius: "1.5rem 1.5rem 0 0",
        "&:dir(rtl)": {
            margin: "65px -20px -11vh 0",
        },
    },
}));

const PaymentForm: FC<IxopayFormProps> = observer(({ data }) => {
    const { t } = useTranslation();
    const { isPaymentMapFormShown, setIsPaymentMapFormShown } = useAppData();
    const { isMobile } = useDeviceDetect();

    const hasFormData = Boolean(data);

    const handleContinueClick = () => {
        handleAmpliLoaded(() =>
            ampli.subscriptionContinueClicked({
                type: "location_map_v1",
                plan: data?.plan?.name || "",
            })
        );
        setIsPaymentMapFormShown(true);
    };

    if (!hasFormData) return null;

    return (
        <>
            {isMobile && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: "-20px",
                        width: "100vw",
                        height: "calc(100% + 11vh)",
                        background: "url(/images/paywall-map-bg.jpg) center / cover no-repeat",
                        zIndex: 0,
                    }}
                />
            )}
            <StyledPaywallContainer
                sx={{
                    border: isPaymentMapFormShown ? `2px solid ${themeColors.background.brightGreen}` : undefined,
                    zIndex: 1,
                    position: "relative",
                    minHeight: "750px",
                }}
            >
                <Stack direction={isMobile ? "column" : "row"}>
                    <Stack
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "48%",
                            },
                            flexShrink: 0,
                        }}
                    >
                        <FakePaywallMap discountRate={50} withStepBack />
                        {(!isMobile || !isPaymentMapFormShown) && (
                            <Box
                                sx={{
                                    padding: "0 20px",
                                }}
                            >
                                <Typography
                                    variant="body1Bold"
                                    fontSize="24px"
                                    textAlign="center"
                                    sx={{ display: "block", mb: "1rem", color: themeColors.text.white }}
                                >
                                    {t("Unlimited Access")}
                                </Typography>
                                {benefits.map(({ text, icon }) => (
                                    <IconTextItem key={`benefit-${text}`} icon={icon} text={t(text)} />
                                ))}

                                {isMobile && (
                                    <LoadingButton
                                        variant="themePrimarySmall"
                                        fullWidth
                                        disableRipple
                                        disableElevation
                                        onClick={handleContinueClick}
                                        sx={{
                                            width: "100%",
                                            px: "unset",
                                            mb: "1rem",
                                            mt: { xs: 0, sm: "2.3rem" },
                                            maxWidth: "100%",
                                            textTransform: "uppercase",
                                            background: themeColors.background.brightGreen,
                                        }}
                                    >
                                        <Typography variant="bodyInterExtraBold">{t("Continue")}</Typography>
                                    </LoadingButton>
                                )}
                            </Box>
                        )}
                    </Stack>

                    <Stack
                        flex={1}
                        sx={{
                            padding: { xs: "0 1rem 2rem", sm: "0 34px 0 40px" },
                            display: {
                                xs: isPaymentMapFormShown ? "flex" : "none",
                                sm: "flex",
                            },
                        }}
                    >
                        <FormWithDiscountAndDisclaimer data={data} />
                    </Stack>
                </Stack>
            </StyledPaywallContainer>
        </>
    );
});

export default PaymentForm;
