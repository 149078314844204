import { FC } from "react";
import { Stack, styled, TextField as MUITextField, Typography } from "@mui/material";
import type { TextFieldProps } from "./types";
import { themeColors } from "constants/colors.const";
import { themeFonts } from "mui-theme/themeFonts.const";

export const textFieldProps = {
    "& .MuiInputBase-root": {
        borderRadius: "1rem",
        border: `2px solid ${themeColors.background.whiteOpacity4}`,
        backgroundColor: themeColors.background.secondary,
        "&.Mui-focused": {
            borderColor: themeColors.brandColors.brandGreen,
            "& input": {
                borderColor: themeColors.brandColors.brandGreen,
            },
        },
        "&.Mui-error": {
            borderColor: themeColors.state.error,
            color: themeColors.state.error,
            "& input": {
                color: themeColors.state.error,
            },
        },
        "&.Mui-focused:not(.Mui-focused.Mui-error)": {
            borderColor: themeColors.brandColors.brandGreen,
        },
        "& fieldset": {
            borderColor: "transparent !important",
        },
        "& ::placeholder": {
            opacity: 1,
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "21px",
            fontWeight: 500,
            color: themeColors.background.whiteOpacity4,
        },
    },
    "& input": {
        padding: "1.25rem 1.5rem",
        color: themeColors.text.white,
        fontWeight: 500,
        fontSize: "20px",
        fontFamily: themeFonts.sourceCodePro,
        background: themeColors.background.secondary,
        borderRadius: "1rem",
        height: "63px",
        maxHeight: "63px",
        boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${themeColors.background.secondary} inset`,
        WebkitTextFillColor: themeColors.text.white,
    },
};

const StyledTextField = styled(MUITextField)(() => textFieldProps);

export const TextField: FC<TextFieldProps> = ({
    helperText,
    errorText,
    showErrorText = true,
    variant = "filled",
    label,
    labelPlacement = "title",
    InputProps = {},
    className,
    ...props
}) => {
    const onKeyPress = !props.multiline
        ? (e: React.KeyboardEvent<HTMLDivElement>) => {
              e.key === "Enter" && e.preventDefault();
          }
        : undefined;

    return (
        <Stack direction="column" gap={0.5}>
            {labelPlacement === "title" ? <Typography variant="caption">{label}</Typography> : null}
            <StyledTextField
                className={`app-text-field ${className ?? ""}`}
                variant={variant}
                error={Boolean(errorText)}
                label={labelPlacement === "legend" ? label : undefined}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{ onKeyPress, ...InputProps }}
                helperText={(showErrorText && errorText) || helperText}
                fullWidth={true}
                {...props}
            />
        </Stack>
    );
};
