import { forwardRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box } from "@mui/material";

import { FormProps } from "./types";

// eslint-disable-next-line react/display-name
export const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => {
    const { children, id, onSubmit, mode = "all", className, ...formOptions } = props;

    const methods = useForm({
        mode,
        ...formOptions,
    });

    return (
        <FormProvider {...methods}>
            <form
                id={id}
                ref={ref}
                noValidate
                className={className}
                onSubmit={methods.handleSubmit(onSubmit.bind(null, methods) as SubmitHandler<Record<string, any>>)}
                style={{ width: "100%" }}
            >
                {typeof children === "function" ? children(methods) : <Box>{children}</Box>}
            </form>
        </FormProvider>
    );
});
