import { Stack, SxProps, Typography } from "@mui/material";
import { formatSecondsIntoMinutes } from "helpers/dateTime";
import { useTranslation } from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { ReactComponent as TimerIcon } from "assets/icons/timer.svg";
import { loadFromSessionStorage, saveToSessionStorage } from "services/storage";

interface Props {
    initialSeconds: number;
    sx?: SxProps;
}

export const CountdownTimer = ({ initialSeconds, sx }: Props) => {
    const { t } = useTranslation();
    const storageSecondsLeft = loadFromSessionStorage("secondsLeft");
    const [secondsLeft, setSecondsLeft] = useState(!isNaN(Number(storageSecondsLeft)) ? Number(storageSecondsLeft) : initialSeconds);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (secondsLeft > 0) {
                setSecondsLeft((prev) => prev - 1);
            } else {
                clearTimeout(timer);
                saveToSessionStorage("secondsLeft", 0);
            }
        }, 1000);

        return () => {
            saveToSessionStorage("secondsLeft", secondsLeft.toString());
            clearTimeout(timer);
        };
    }, [secondsLeft]);

    return (
        <Stack
            sx={{
                flexDirection: "row",
                gap: "10px",
                background: "linear-gradient(45deg, #0A2C27 -0.09%, #384283 99.91%)",
                borderRadius: "32px",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px",
                ...sx,
            }}
        >
            <TimerIcon />
            <Typography variant="dmSansBody2Semibold" sx={{ color: "text.secondary" }}>
                {t("offer_end_timer_text", { timeleft: formatSecondsIntoMinutes(secondsLeft) })}
            </Typography>
        </Stack>
    );
};
