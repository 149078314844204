import { User, UserMetadata } from "@supabase/supabase-js";
import { action, makeObservable, observable } from "mobx";
import { checkPaymentUserSubscription, fetchPaymentUserInfo } from "http-client/payment.client";
import { RootStore } from "./root.store";
import { UserProfile } from "interfaces/user";
import { getUserMetadata, updateWebSession } from "http-client/session.client";

// TODO: change types when backend functionality is ready
type UserSubscription = Record<string, any>;

export type IpInfoRecordData = {
    ip: string | null;
    postal: string | null;
    country: string | null;
    city: string | null;
    loc: string | null;
    timezone: string | null;
    state: string | null;
};

export type UserWebSession = {
    [key: string]: any;
    session?: {
        userId?: string;
        flowOuter?: boolean;
        trialGtagSent?: boolean;
        isTestUser?: boolean;
        gclid?: string | null;
        ipInfo?: IpInfoRecordData | null;
        user?: Record<string, any> | null;
        abTestData?: Record<string, any> | null;
        ampliAttributesUpdated?: boolean | null;
        webAttributionSent?: boolean | null;
        quiz?: Record<string, any> | null;
        extraOfferStepsShowed?: number;
    };
};

export class UserStore {
    rootStore: RootStore;
    user: User | null = null;
    userProfile: UserProfile | null = null;
    userSubscription: UserSubscription | null = null;
    userWebSession: UserWebSession | null = null;
    loading = false;
    webSessionLoading = true;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            user: observable,
            userProfile: observable,
            userSubscription: observable,
            userWebSession: observable,
            loading: observable,
            webSessionLoading: observable,
            setUser: action,
            setUserProfile: action,
            setUserSubscription: action,
            setLoading: action,
            setWebSession: action,
            setWebSessionLoading: action,
        });
        this.rootStore = rootStore;
    }

    setUserProfile = (profile: UserProfile | null) => {
        this.userProfile = profile;
    };

    setUserSubscription = (subscription: UserSubscription | null) => {
        this.userSubscription = subscription;
    };

    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    setWebSessionLoading = (loading: boolean) => {
        this.webSessionLoading = loading;
    };

    setWebSession = (session: UserMetadata | null) => {
        this.userWebSession = session;
    };

    updateUserWebSession = async (session: Record<string, any>) => {
        const user = await updateWebSession(session);
        this.setUser(user);
    };

    setUser = (user: User | null) => {
        this.user = user;

        if (!user) {
            this.setWebSession(null);
        }

        const userMetaData = user?.user_metadata;
        this.setWebSession(userMetaData || null);
    };

    fetchUserProfile = async (email?: string | null | undefined) => {
        let profile: UserProfile | null = null;

        if (email) {
            profile = await fetchPaymentUserInfo(email);
        }

        this.setUserProfile(profile);
    };

    fetchUserSession = async (): Promise<UserWebSession | null> => {
        this.setWebSessionLoading(true);

        const session = await getUserMetadata();
        this.setWebSession(session);

        /* Set here any Mobx observables that depend on Web session */
        const sessionFlowOuter = session?.session?.flowOuter;
        if (sessionFlowOuter != null) {
            this.rootStore.appStore.setFlowOuter(sessionFlowOuter);
        }

        this.setWebSessionLoading(false);
        return session;
    };

    checkUserSubscription = async (): Promise<UserSubscription | null> => {
        const email = this.user?.email;
        let data: UserSubscription | null = null;

        if (email) {
            data = await checkPaymentUserSubscription(email);
            this.setUserSubscription(data);
        } else {
            this.setUserSubscription(null);
        }

        return data;
    };

    hydrate = (data: any) => {
        if (!data) {
            return;
        }
        if (data.user) {
            this.user = data.user;
        }
    };
}
