import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonProps, Divider, Typography } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import { MakeSubscriptionTransactionParams, makeSubscriptionTransaction, validateMerchant } from "http-client/payment.client";
import { PROJECT_NAME } from "constants/env.const";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { loadApplePayScript } from "helpers/loadApplePayScript";
import { getRecurringPaymentDates } from "./helpers/getRecurringPaymentDates";
import { observer } from "mobx-react-lite";
import { IxopayFormData } from "components/Payment/shared/types";
import { AppRoutes } from "enums/routes.enum";
import { ReactComponent as ApplePayLogo } from "assets/icons/apple-pay.svg";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";
import { useTranslation } from "hooks/useTranslation";
import { useBoolean } from "hooks/useBoolean";

declare global {
    interface Window {
        ApplePaySession?: {
            canMakePayments(): boolean;
            new (version: number, paymentRequest: any): ApplePaySession;
            STATUS_SUCCESS: number;
            STATUS_FAILURE: number;
        };
    }
}

interface ApplePaySession {
    onvalidatemerchant: (event: { validationURL: string }) => void;
    completeMerchantValidation(merchantSession: any): void;
    onpaymentauthorized: (event: { payment: any }) => void;
    completePayment(result: any): void;
    oncancel: (event: any) => void;
    abort(): void;
    begin(): void;
}

interface Props extends ButtonProps {
    data: IxopayFormData;
}

export const ApplePayButton: React.FC<Props> = observer(({ data, sx }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [canMakePayments, setCanMakePayments] = useState(false);
    const [disabled, setDisabled] = useBoolean(false);
    const userStore = useUserStore();
    const { amplitudeUserId } = useAppData();
    const applePaySessionRef = useRef(window.ApplePaySession);

    const { plan, applepaySuccessQuery, applepayDeclineQuery } = data;
    const { trialDuration, amplitudeId, trialPrice, price, id } = plan;

    useEffect(() => {
        const loadApplePay = async () => {
            try {
                if (!document.getElementById("apple-pay-script")) {
                    await loadApplePayScript();
                }
                if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
                    applePaySessionRef.current = window.ApplePaySession;
                    setCanMakePayments(true);
                }
            } catch (error) {
                console.error("Failed to load Apple Pay script:", error);
            }
        };

        loadApplePay();
    }, [userStore]);

    const handleApplePayButtonClick = () => {
        setDisabled.on();
        handleAmpliLoaded(() => ampli.applePayButtonClicked());
        const ApplePaySession = applePaySessionRef.current;
        const paymentRequestProps = {
            countryCode: "EE",
            currencyCode: "USD",
            merchantCapabilities: ["supports3DS", "supportsDebit", "supportsCredit"],
            supportedNetworks: ["visa", "masterCard", "amex", "discover"],
            lineItems: [
                {
                    label: amplitudeId,
                    amount: trialPrice?.toString() || "",
                    paymentTiming: "recurring",
                    recurringPaymentEndDate: getRecurringPaymentDates({ trialDuration: trialDuration || "" }).recurringPaymentEndDate,
                },
                {
                    label: "Recurring",
                    amount: price?.toString() || "",
                    paymentTiming: "recurring",
                    recurringPaymentStartDate: getRecurringPaymentDates({ trialDuration: trialDuration || "" }).recurringPaymentStartDate,
                },
            ],
            total: {
                label: "Geomobile",
                amount: trialPrice?.toString() || "",
            },
        };

        if (ApplePaySession) {
            const session = new ApplePaySession(3, paymentRequestProps);

            session.onvalidatemerchant = async (event) => {
                try {
                    const merchantSession = await validateMerchant({
                        validationURL: event.validationURL,
                        project: PROJECT_NAME,
                    });
                    session.completeMerchantValidation(merchantSession);
                } catch (err) {
                    console.error("Error validating merchant:", err);
                    session.abort?.();
                }
            };

            session.onpaymentauthorized = async (event) => {
                try {
                    const token = event.payment;
                    const ccToken = `applepay:${JSON.stringify(token)}`;
                    const currentHostUrl = window.location.origin;

                    const params: MakeSubscriptionTransactionParams = {
                        userAmpliId: amplitudeUserId,
                        ccToken,
                        project: PROJECT_NAME,
                        subscriptionPlanId: id || "",
                        applicationContext: {
                            successUrl: `${currentHostUrl}/secure-flow-result?success=true`,
                            cancelUrl: `${currentHostUrl}/secure-flow-result?success=false`,
                            errorUrl: `${currentHostUrl}/secure-flow-result?success=false`,
                        },
                        customer: {
                            firstName: event.payment.billingContact?.givenName || "",
                            lastName: event.payment.billingContact?.familyName || "",
                        },
                    };

                    const transactionResponse = await makeSubscriptionTransaction(params);

                    if (transactionResponse.success) {
                        session.completePayment(ApplePaySession.STATUS_SUCCESS);

                        setTimeout(() => {
                            navigate({ pathname: AppRoutes.SUCCESS, search: createSearchParams(applepaySuccessQuery).toString() });
                        }, 800);
                    } else {
                        session.completePayment(ApplePaySession.STATUS_FAILURE);

                        setTimeout(() => {
                            navigate({ pathname: AppRoutes.DECLINE, search: createSearchParams(applepayDeclineQuery).toString() });
                        }, 800);
                    }
                } catch (error) {
                    console.error("Error processing Apple Pay payment:", error);
                    session.completePayment(ApplePaySession.STATUS_FAILURE);

                    setTimeout(() => {
                        navigate(`${window.location.origin}/secure-flow-result?success=false`);
                    }, 800);
                }
            };

            session.oncancel = (event) => {
                // eslint-disable-next-line
                console.log("event oncancel", event);
                setDisabled.off();
            };

            session.begin();
        }
    };

    if (!canMakePayments) {
        return null;
    }

    return (
        <>
            <Button
                onClick={handleApplePayButtonClick}
                disabled={disabled}
                className="applepay-btn"
                sx={{
                    width: "100%",
                    height: "56px",
                    borderRadius: "8px",
                    backgroundColor: "background.grey",
                    boxShadow: "none",
                    border: "1px solid #efefef",
                    "&:hover": {
                        backgroundColor: "text.black",
                        border: "1px solid #efefef",
                    },
                    "&:disabled": {
                        opacity: 0.2,
                        backgroundColor: "transparent",
                    },
                    ...sx,
                }}
            >
                <ApplePayLogo />
            </Button>
            <Divider
                sx={(theme) => ({
                    "&:before, &:after": { borderTop: "1px solid #383838", mt: "3px" },
                    color: theme.palette.text.disabled,
                    mb: "16px",
                })}
            >
                <Typography variant="dmSansBody2Regular" fontSize="12px" lineHeight={1}>
                    {t("or pay by a card")}
                </Typography>
            </Divider>
        </>
    );
});
