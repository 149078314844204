import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { IXOPAY_INTEGRATION_KEY } from "constants/env.const";
import { AppRoutes } from "enums/routes.enum";
import { checkPaymentOneClickInfo, makeSubscriptionTransaction, MakeSubscriptionTransactionParams } from "http-client/payment.client";
import { SubscriptionSubscribeClickedProperties, ampli } from "services/ampli";
import { loadFromSessionStorage, saveToSessionStorage } from "services/storage";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { PaywallType, setFieldsStyles } from "../utils";
import { IxopayFormData } from "../types";

interface Props {
    formSpyRef: any;
    paymentJsRef: any;
    hasFormData: boolean;
    data: IxopayFormData | null;
    userEmail: string;
    paywall: PaywallType;
    submitEventProps?: SubscriptionSubscribeClickedProperties;
}

export const useIxopayFormData = ({ formSpyRef, paymentJsRef, hasFormData, data, userEmail, paywall, submitEventProps }: Props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { amplitudeUserId, abTestData } = useAppData();
    const { userWebSession } = useUserStore();
    const { plan, successPage, successQuery, declinePage, declineQuery } = data || {};
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [oneClickCardData, setOneClickCardData] = useState<Record<string, any> | null>(null);
    const abTestPaywall = userWebSession?.session?.abTestData?.abTestPaywall || abTestData?.abTestPaywall;

    const numberOn = {
        blur: () => {
            formSpyRef.current?.clearErrors("number");
        },
        input: () => {
            formSpyRef.current?.clearErrors("number");
        },
    };
    const cvvOn = {
        blur: () => {
            formSpyRef.current?.clearErrors("cvv");
        },
        input: () => {
            formSpyRef.current?.clearErrors("cvv");
        },
    };

    useEffect(() => {
        if (hasFormData && oneClickCardData && !oneClickCardData?.token) {
            paymentJsRef.current = new window.PaymentJs();
            paymentJsRef.current?.init(IXOPAY_INTEGRATION_KEY, "number", "cvv", (payment: any) => {
                setFieldsStyles({ payment, numberOn, cvvOn, paywall });
                payment.setNumberPlaceholder(t("Card number"));
                payment.setCvvPlaceholder(t("CVV/CVC"));
                setSpinnerLoading(false);
            });
        }

        const handle3DSPostMessage = async (event: any) => {
            const { type, payload } = event.data || {};

            if (type === "3DS-complete") {
                const isSuccess = payload?.success === "true";
                if (isSuccess) {
                    const paymentOneClickInfo = await checkPaymentOneClickInfo(userEmail);
                    const { uuid, paymentData } = paymentOneClickInfo || {};
                    const { firstSixDigits, lastFourDigits } = paymentData || {};

                    if (uuid) {
                        saveToSessionStorage("one-click-card", JSON.stringify({ token: uuid, card: `${firstSixDigits}******${lastFourDigits}` }));
                    }
                }

                setTimeout(() => {
                    const page = isSuccess ? AppRoutes.SUCCESS : declinePage;
                    const query = isSuccess ? successQuery : declineQuery;

                    // If you need to remove an iframe, you can do it here
                    // document.querySelector(".ixopay-3ds-flow iframe")?.remove();
                    navigate({ pathname: page, search: createSearchParams(query).toString() });
                }, 1000);
            }
        };

        window.addEventListener("message", handle3DSPostMessage, false);
        return () => {
            window.removeEventListener("message", handle3DSPostMessage);
        };
    }, [hasFormData, oneClickCardData, i18n.language]);

    const generateTransactionParams = (token: string, cardData) => {
        const currentHostUrl = window.location.origin;

        const [firstName, lastName = ""] = cardData.full_name.split(" ");

        const params: Partial<MakeSubscriptionTransactionParams> = {
            userAmpliId: amplitudeUserId,
            ccToken: token,
            subscriptionPlanId: plan?.id as string,
            applicationContext: {
                successUrl: `${currentHostUrl}/secure-flow-result?success=true`,
                cancelUrl: `${currentHostUrl}/secure-flow-result?success=false`,
                errorUrl: `${currentHostUrl}/secure-flow-result?success=false`,
            },
            customer: {
                firstName,
                lastName,
            },
        };

        return { params };
    };

    const handleTransactionResponse = async (transactionResponse: any) => {
        if (transactionResponse?.success && transactionResponse?.returnType === "REDIRECT") {
            const iframe = document?.createElement("iframe");
            iframe.src = transactionResponse.redirectUrl;
            (iframe as any).style =
                "position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; background-color: #fff; z-index:999999;";
            document?.querySelector(".ixopay-3ds-flow")?.appendChild(iframe);
        }

        if (data?.successPage && transactionResponse?.success && transactionResponse?.returnType === "FINISHED") {
            setTimeout(() => {
                navigate({ pathname: successPage, search: createSearchParams(successQuery).toString() });
            }, 800);
        }

        if (data?.declinePage && !transactionResponse?.success) {
            setTimeout(() => {
                navigate({ pathname: declinePage, search: createSearchParams(declineQuery).toString() });
            }, 800);
        }
    };

    const handleSubmit = async (methods: UseFormReturn, values: any): Promise<void> => {
        if (!paymentJsRef.current || !data) return;
        setLoading(true);

        const props = submitEventProps ?? {
            type: abTestPaywall,
            product_id: plan?.planId || "",
            plan: plan?.name || "",
        };

        handleAmpliLoaded(() => ampli.subscriptionSubscribeClicked(props));

        try {
            const [month, year] = values.expiry.split("/");
            const formData = {
                card_holder: values.card_holder,
                month,
                year: `20${year}`,
            };

            paymentJsRef.current.tokenize(
                formData,
                async (token: string, cardData) => {
                    setSpinnerLoading(true);
                    const { params } = generateTransactionParams(token, cardData);
                    const transactionResponse = await makeSubscriptionTransaction(params as MakeSubscriptionTransactionParams);
                    await handleTransactionResponse(transactionResponse).catch();
                    setLoading(false);
                },
                (errors: any) => {
                    for (const error of errors) {
                        let hasSetExpiryError = false;

                        if (error.attribute === "month") {
                            hasSetExpiryError = true;
                            methods.setError("expiry", {
                                type: "server",
                                message: error.message,
                            });
                        }

                        if (error.attribute === "year" && !hasSetExpiryError) {
                            methods.setError("expiry", {
                                type: "server",
                                message: error.message,
                            });
                        }

                        methods.setError(error.attribute, {
                            type: "server",
                            message: error.message,
                        });
                    }
                    setLoading(false);
                }
            );
        } catch (err: any) {
            console.error("Form data has not been submitted");
        }
    };

    useEffect(() => {
        setOneClickCardData(loadFromSessionStorage("one-click-card") || {});
    }, []);

    return {
        handleSubmit,
        numberOn,
        cvvOn,
        spinnerLoading,
        loading,
        setSpinnerLoading,
    };
};
