import { FC, useEffect } from "react";
import { useTranslation } from "hooks/useTranslation";
import { Box, DialogProps, IconButton, Stack, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { usePaymentLogic } from "components/Payment";
import { FormWithDiscountAndDisclaimer } from "components/Payment/shared/components";
import { StyledDialog } from "../shared.styled";
import { useLocation } from "react-router-dom";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";

interface Props extends DialogProps {
    onClose?: () => void;
}

export const PaymentModal: FC<Props> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const planMatchCallback = (plan) => plan.trialPrice === 1;
    const { ixopayFormData } = usePaymentLogic({ planMatchCallback, extraDeclineQuery: { extraOfferPath: pathname, extraOffer: "payment" } });

    useEffect(() => {
        if (!open || !ixopayFormData?.plan) return;

        const params = {
            flow: "nonlegal",
            type: `offer_${ixopayFormData?.plan?.amplitudeId}`,
        };
        handleAmpliLoaded(() => ampli.subscriptionShown(params));
    }, [open, ixopayFormData?.plan]);

    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiPaper-root": {
                    backgroundColor: "background.darkGrey",
                    position: "relative",
                    width: "478px",
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    width: "24px",
                    height: "24px",
                    position: "absolute",
                    top: "16px",
                    right: "16px",
                    "& svg": {
                        fill: theme.palette.text.primary,
                    },
                })}
            >
                <CloseOutlinedIcon />
            </IconButton>
            <Stack sx={{ overflowY: "auto" }}>
                <Stack
                    sx={{
                        mb: { xs: "-110px", lmd: "-130px" },
                    }}
                >
                    <Typography
                        variant="body1CaptionBold"
                        sx={(theme) => ({
                            color: theme.palette.text.secondary,
                            textTransform: "uppercase",
                            textAlign: "center",
                            padding: "0 10px 10px",
                            borderRadius: "0 0 1rem 1rem",
                            background: theme.palette.background.brightGreen,
                            whiteSpace: "nowrap",
                            alignSelf: "center",
                            mb: "32px",
                        })}
                    >
                        {t("SPECIAL OFFER")} - 90%
                    </Typography>
                    {ixopayFormData && (
                        <Box
                            sx={{
                                padding: { xs: "0 16px 30px", lmd: "0 75px 50px" },
                                "& .applepay-btn": {
                                    background: "transparent",
                                },
                                "& .form-inputs-wrap": {
                                    px: 0,
                                },
                            }}
                        >
                            <FormWithDiscountAndDisclaimer
                                data={ixopayFormData}
                                submitButtonTitle={t("Continue").toUpperCase()}
                                submitEventProps={{
                                    type: `offer_${ixopayFormData?.plan?.amplitudeId}` || "",
                                    product_id: ixopayFormData?.plan?.planId || "",
                                    plan: ixopayFormData?.plan?.name || "",
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </Stack>
        </StyledDialog>
    );
};
