import { Stack, StackProps, Typography, styled } from "@mui/material";
import { useTranslation } from "hooks/useTranslation";
import { FC } from "react";

const StyledBadge = styled(Stack)(({ theme }) => ({
    position: "absolute",
    top: "-1px",
    right: "-1px",
    borderRadius: "0 16px 0 16px",
    border: `1px solid ${theme.palette.brandColors.brandWhite}`,
    padding: "2px 7px",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "91px",
}));

interface Props extends StackProps {
    title: string;
    subtitle: string;
    specialPrice: string;
    specialPriceAddOn: string;
    badgeTitle: string;
    isActive: boolean;
    accentColor: string;
    activePriceColor: string;
    onClick?: () => void;
}

export const PlanVariantCard: FC<Props> = ({
    title,
    subtitle,
    specialPrice,
    specialPriceAddOn,
    badgeTitle,
    isActive,
    onClick,
    accentColor,
    activePriceColor,
    sx,
}) => {
    const { detect } = useTranslation();

    return (
        <Stack
            sx={{
                flexDirection: detect.isArabicLanguage ? "row-reverse" : "row",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                padding: "11px 16px",
                borderRadius: "16px",
                border: "1px solid",
                borderColor: accentColor,
                boxShadow: isActive ? `0px 0px 4px 0px ${accentColor}` : "none",
                cursor: "pointer",
                backgroundColor: isActive ? "rgba(114, 120, 154, 0.2)" : "transparent",
                ...sx,
            }}
            onClick={onClick}
        >
            <StyledBadge
                sx={{
                    backgroundColor: accentColor,
                }}
            >
                <Typography variant="dmSansBody2Semibold" sx={{ fontSize: "11px", lineHeight: "14px", display: "block", color: "text.secondary" }}>
                    {badgeTitle}
                </Typography>
            </StyledBadge>
            <Stack gap="2px">
                <Typography variant="dmSansBody2Semibold" sx={{ color: "text.secondary", fontSize: "16px" }}>
                    {title}
                </Typography>
                <Typography variant="dmSansBody2Semibold" sx={{ color: isActive ? "text.disabled" : "text.secondary" }}>
                    {subtitle}
                </Typography>
            </Stack>
            <Stack direction="row" gap="4px" alignItems="center">
                <Typography
                    variant="dmSansH4Medium"
                    sx={{
                        color: isActive ? activePriceColor : "text.secondary",
                        fontSize: isActive ? "23px" : "20px",
                        fontWeight: isActive ? 600 : 500,
                        transition: ".05s",
                    }}
                >
                    {specialPrice}
                </Typography>
                <Typography variant="dmSansBody2Semibold" sx={{ color: "text.secondary" }}>
                    {specialPriceAddOn}
                </Typography>
            </Stack>
        </Stack>
    );
};
