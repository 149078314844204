import { themeColors } from "constants/colors.const";
import { UseFormSetValue } from "react-hook-form";

export enum PaywallType {
    map = "map",
    specialOffer = "specialOffer",
    default = "default",
}

const fieldStyles = {
    [PaywallType.default]: {
        width: "100%",
        boxSizing: "border-box",
        height: "100%",
        borderRadius: "1rem",
        border: `2px solid ${themeColors.background.whiteOpacity4}`,
        backgroundColor: themeColors.background.secondary,
        color: themeColors.text.white,
        outline: "none",
        fontFamily: "Tahoma, Arial, sans-serif",
        padding: "14px",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "1",
        letterSpacing: "0.3px",
    },
    [PaywallType.specialOffer]: {
        width: "100%",
        boxSizing: "border-box",
        height: "100%",
        borderRadius: "1rem",
        border: `2px solid ${themeColors.brandColors.brandGreen}`,
        backgroundColor: "transparent",
        color: themeColors.text.black,
        outline: "none",
        fontFamily: "Tahoma, Arial, sans-serif",
        padding: "14px",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "1",
        letterSpacing: "0.3px",
    },
    [PaywallType.map]: {
        width: "100%",
        boxSizing: "border-box",
        height: "100%",
        WebkitAppearance: "none",
        borderRadius: 0,
        border: "none",
        borderBottom: `1px solid ${themeColors.background.borderLightGrey}`,
        backgroundColor: "transparent",
        color: themeColors.text.white,
        outline: "none",
        fontFamily: "Tahoma, Arial, sans-serif",
        padding: "1rem 0 .5rem",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "1",
        letterSpacing: "2px",
    },
};

const allFocusStyles = {
    [PaywallType.default]: {
        border: `2px solid ${themeColors.brandColors.brandGreen}`,
    },
    [PaywallType.specialOffer]: {
        border: `2px solid ${themeColors.brandColors.brandGreen}`,
    },
    [PaywallType.map]: {
        borderColor: themeColors.brandColors.brandGreen,
    },
};

export const setFieldsStyles = ({
    payment,
    numberOn,
    cvvOn,
    paywall,
}: {
    payment: any;
    numberOn: Record<string, () => void>;
    cvvOn: Record<string, () => void>;
    paywall?: PaywallType;
}) => {
    let numberFocused = false;
    let cvvFocused = false;

    const getPaywallStyles = (paywall?: PaywallType) => fieldStyles[paywall ?? PaywallType.default];
    const getFocusStyles = (paywall?: PaywallType) => allFocusStyles[paywall ?? PaywallType.default];

    const style = getPaywallStyles(paywall);
    const focusStyle = getFocusStyles(paywall);

    payment.setNumberStyle(style);
    payment.setCvvStyle(style);

    // Focus events
    payment.numberOn("focus", () => {
        numberFocused = true;
        payment.setNumberStyle(focusStyle);
    });
    payment.cvvOn("focus", () => {
        cvvFocused = true;
        payment.setCvvStyle(focusStyle);
    });

    payment.numberOn("blur", () => {
        numberFocused = false;
        payment.setNumberStyle(style);
        numberOn.blur?.();
    });

    payment.cvvOn("blur", () => {
        cvvFocused = false;
        payment.setCvvStyle(style);
        cvvOn.blur?.();
    });

    payment.numberOn("mouseout", () => {
        if (!numberFocused) {
            payment.setNumberStyle(style);
        }
    });

    payment.cvvOn("mouseout", () => {
        if (!cvvFocused) {
            payment.setCvvStyle(style);
        }
    });

    payment.numberOn("input", () => {
        numberOn.input?.();
    });
    payment.cvvOn("input", () => {
        cvvOn.input?.();
    });
};

export const filterFieldsText = (values: Record<string, any>, setValue: UseFormSetValue<any>) => {
    const { card_holder: _card_holder, expiry: _expiry } = values;

    const card_holder = _card_holder.replace(/[^a-zA-Z ]+/g, "");
    const expiry = _expiry.replace(/[^0-9/]/g, "");

    if (_card_holder && _card_holder.match(/[^a-zA-Z ]+/g, "")) {
        setValue("card_holder", card_holder);
        return;
    }

    if (expiry && expiry?.length > 2 && !expiry.includes("/")) {
        setValue("expiry", `${expiry.substring(0, 2)}/${values.expiry.substring(2)}`);
        return;
    }
    if (expiry && expiry?.length > 5) {
        setValue("expiry", `${expiry.substring(0, 5)}`);
        return;
    }
    if (expiry && expiry?.length === 3) {
        setValue("expiry", `${expiry.replace("/", "")}`);
        return;
    }
    if (_expiry && _expiry.match(/[^0-9/]/g, "")) {
        setValue("expiry", expiry);
        return;
    }
};
