import { FC } from "react";
import { GoogleMap, GoogleMapProps, useJsApiLoader } from "@react-google-maps/api";
import { CircularProgress } from "@mui/material";

import { GOOGLE_MAP_API_KEY } from "constants/env.const";
import { fakeSearchMapTheme } from "constants/fakeSearchMapTheme.const";

interface MapProps extends GoogleMapProps {
    lat: number;
    lng: number;
    theme?: any[];
}

const FakeSearchMap: FC<MapProps> = ({ lat, lng, theme, ...rest }) => {
    const position = { lat, lng };

    const { isLoaded, loadError } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
    });

    if (loadError) return <div>Error loading maps</div>;

    if (!isLoaded) return <CircularProgress />;

    return (
        <GoogleMap
            center={position}
            options={{
                styles: theme ?? fakeSearchMapTheme,
                keyboardShortcuts: false,
                streetViewControl: false,
                scaleControl: false,
                zoomControl: false,
                mapTypeControl: false,
                panControl: false,
                rotateControl: false,
                fullscreenControl: false,
                draggable: false,
                backgroundColor: "#242f3e",
            }}
            {...rest}
        ></GoogleMap>
    );
};

export default FakeSearchMap;
