/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull geomobile-w2w'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/implementation/geomobile-w2w)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '677b10303343e8b39d0265c8ca09d3aa',
  development: '83c5bc141d9f98c6a63929a57837c158'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'geomobile-w2w',
    versionId: '64d8f4d3-6465-48a4-8377-52c42f3e3f61'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  ab_test_1_show_paypal_20240307?: string;
  /**
   * **(af_ad) - передает креатив (paramKey: 'original_url_ad'**
   */
  ad?: string;
  /**
   * **(af_adset) - передает адсет (paramKey: 'no_adset'**
   */
  adGroupName?: string;
  /**
   * **("c" в ссылке onelink) - передает кампанию (paramKey: 'utm_campaign')**
   */
  campaignName?: string;
  cohort_date?: string;
  cohort_month?: string;
  cohort_week?: string;
  cohort_year?: string;
  device_id?: string;
  device_language?: string;
  fbclid?: string;
  gbraid?: string;
  gclid?: string;
  /**
   * булеве проперті, яке вказує на наявність у користувача акаунту
   */
  has_account?: boolean;
  initial_dclid?: string;
  initial_fbclid?: string;
  initial_gbraid?: string;
  initial_gclid?: string;
  initial_ko_click_id?: string;
  initial_li_fat_id?: string;
  initial_msclkid?: string;
  initial_referrer?: string;
  initial_referring_domain?: string;
  initial_rtd_cid?: string;
  initial_ttclid?: string;
  initial_twclid?: string;
  initial_utm_ad?: string;
  initial_utm_campaign?: string;
  initial_utm_content?: string;
  initial_utm_id?: string;
  initial_utm_medium?: string;
  initial_utm_placement?: string;
  initial_utm_source?: string;
  initial_utm_term?: string;
  initial_wbraid?: string;
  network?: string;
  payment_screen_options_legal?: string;
  payment_screen_options_non_legal?: string;
  /**
   * **EMPTY
   *  название активной подписки в формате: weekly_9.99$/weekly_9.99$\_with_trial_3d**
   */
  product_id?: string;
  referrer?: string;
  referring_domain?: string;
  /**
   * **true/false**
   */
  subscription_active?: string;
  user_email?: string;
  utm_ad?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_placement?: string;
  utm_source?: string;
  utm_term?: string;
  wbraid?: string;
}

export interface AccountLogoutClickedProperties {
  mode?: string;
}

export interface AccountScreenShownProperties {
  mode?: string;
}

export interface BehaviorChangesChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface ButtonClickedProperties {
  button_name: string;
}

export interface CheckoutContinueClickedProperties {
  product_id: string;
}

export interface CheckoutErrorShownProperties {
  product_id: string;
}

export interface CheckoutErrorTryAgainCkickedProperties {
  product_id: string;
}

export interface CheckoutShownProperties {
  product_id: string;
  source?: string;
}

export interface CollectInfoChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface ContactFormSentProperties {
  subject: string;
}

export interface ContactFormShownProperties {
  mode?: string;
  user_status?: string;
}

export interface FilterStatusSelectedProperties {
  mode?: string;
  status_type: string;
}

export interface GenderChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface LandingLocateClickProperties {
  country_code: string;
  entered_number?: string;
  error_type: string;
  landing_type?: string;
  success: boolean;
}

export interface LandingMenuClickedProperties {
  chosen_navigation_item: string;
  landing_type?: string;
}

export interface LandingScreenShownProperties {
  ab_paywall: string;
  landing_type?: string;
}

export interface LandingScrollProperties {
  landing_type?: string;
}

export interface LandingTryNowClickProperties {
  landing_type?: string;
}

export interface LatenightCallsChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface LoginErrorProperties {
  landing_type?: string;
  reason: string;
}

export interface LoginForgotClickedProperties {
  landing_type?: string;
}

export interface LoginLoginClickProperties {
  email?: string;
  landing_type?: string;
  success: boolean;
}

export interface LoginScreenShownProperties {
  landing_type?: string;
}

export interface LoginSuccessfullyProperties {
  landing_type?: string;
}

export interface MessageExitProperties {
  mode?: string;
}

export interface MessageSentFailureProperties {
  mode?: string;
  reason: string;
}

export interface MessageSentSuccessProperties {
  email?: string;
  entered_number?: string;
  entered_reward?: string;
  mode?: string;
}

export interface MessagesDeletionChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface NumberDetailsClickedProperties {
  location_id: string;
  mode?: string;
  status: string;
}

export interface NumberDetailsScreenShownProperties {
  mode?: string;
}

export interface NumberLocateClickedProperties {
  country_code: string;
  entered_number?: string;
  error_type: string;
  mode?: string;
  success: boolean;
}

export interface NumberNameChangedProperties {
  mode?: string;
}

export interface NumberNameDeletedProperties {
  mode?: string;
}

export interface OauthButtonClickedProperties {
  provider: string;
}

export interface PartnerHidingSmthChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface PhoneHidingChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface PowerscreenAffairWithStatClickedProperties {
  question_number: string;
  quiz_type?: string;
}

export interface PowerscreenAffairWithStatShownProperties {
  question_number: string;
  quiz_type?: string;
}

export interface PowerscreenCheatingStatClickedProperties {
  question_number: string;
  quiz_type?: string;
}

export interface PowerscreenCheatingStatShownProperties {
  question_number: string;
  quiz_type?: string;
}

export interface ProfileCreationShownProperties {
  quiz_type?: string;
}

export interface SecretivePhoneChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface SignupScreenShownProperties {
  landing_type?: string;
  quiz_type?: string;
}

export interface SignupSignupClickedProperties {
  email?: string;
  landing_type?: string;
  quiz_type?: string;
  success: boolean;
}

export interface SingupErrorProperties {
  landing_type?: string;
  quiz_type?: string;
  reason: string;
}

export interface SingupSuccessfullyProperties {
  landing_type?: string;
  quiz_type?: string;
}

export interface SpecialOfferAcceptedProperties {
  /**
   * Назва оферу в добивалці (перший/другий): initial/followup
   */
  offer_name: string;
}

export interface SpecialOfferRejectedProperties {
  /**
   * Назва оферу в добивалці (перший/другий): initial/followup
   */
  offer_name: string;
}

export interface SpecialOfferShownProperties {
  /**
   * Назва оферу в добивалці (перший/другий): initial/followup
   */
  offer_name: string;
}

export interface SubscriptionClosedProperties {
  type: string;
}

export interface SubscriptionContinueClickedProperties {
  plan: string;
  type: string;
}

export interface SubscriptionShownProperties {
  button?: string;
  flow: string;
  source?: string;
  type: string;
}

export interface SubscriptionSubscribeClickedProperties {
  plan: string;
  product_id: string;
  type: string;
}

export interface SubscriptionSuccessShownProperties {
  payment_provider: string;
}

export interface SuspiciousActivityChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface TimeOutChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface WebAttributionProperties {
  browser: string;
  browser_version: string;
  cohort_day: string;
  cohort_month: string;
  cohort_week: string;
  cohort_year: string;
  country: string;
  device_brand: string;
  device_category: string;
  device_id: string;
  device_language: string;
  device_model: string;
  fbclid: string;
  gclid: string;
  initial_fbclid: string;
  initial_gclid: string;
  initial_utm_ad?: string;
  initial_utm_campaign: string;
  initial_utm_content: string;
  initial_utm_medium: string;
  initial_utm_source: string;
  initial_utm_term: string;
  is_test?: boolean;
  operating_system: string;
  operating_system_version: string;
  project_name: string;
  user_id: string;
  utm_ad?: string;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
}

export interface WebAttributionUpdateProperties {
  browser: string;
  browser_version: string;
  cohort_day: string;
  cohort_month: string;
  cohort_week: string;
  cohort_year: string;
  country: string;
  device_brand: string;
  device_category: string;
  device_id: string;
  device_language: string;
  device_model: string;
  fbclid: string;
  gclid: string;
  initial_fbclid: string;
  initial_gclid: string;
  initial_utm_campaign: string;
  initial_utm_content: string;
  initial_utm_medium: string;
  initial_utm_source: string;
  initial_utm_term: string;
  is_test: string;
  operating_system: string;
  operating_system_version: string;
  project_name: string;
  user_id: string;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
}

export interface WelcomeContinueClickedProperties {
  quiz_type: string;
}

export interface WelcomeShownProperties {
  quiz_type?: string;
}

export interface WhereYourPartnerChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export interface WhereaboutsLyingChosenProperties {
  answer: string;
  question_number: string;
  quiz_type?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountLogoutClicked implements BaseEvent {
  event_type = 'account_logout_clicked';

  constructor(
    public event_properties?: AccountLogoutClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountScreenShown implements BaseEvent {
  event_type = 'account_screen_shown';

  constructor(
    public event_properties?: AccountScreenShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplePayButtonClicked implements BaseEvent {
  event_type = 'apple_pay_button_clicked';
}

export class BehaviorChangesChosen implements BaseEvent {
  event_type = 'behavior_changes_chosen';

  constructor(
    public event_properties: BehaviorChangesChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ButtonClicked implements BaseEvent {
  event_type = 'button_clicked';

  constructor(
    public event_properties: ButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutContinueClicked implements BaseEvent {
  event_type = 'checkout_continue_clicked';

  constructor(
    public event_properties: CheckoutContinueClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutErrorShown implements BaseEvent {
  event_type = 'checkout_error_shown';

  constructor(
    public event_properties: CheckoutErrorShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutErrorTryAgainCkicked implements BaseEvent {
  event_type = 'checkout_error_try_again_ckicked';

  constructor(
    public event_properties: CheckoutErrorTryAgainCkickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutShown implements BaseEvent {
  event_type = 'checkout_shown';

  constructor(
    public event_properties: CheckoutShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollectInfoChosen implements BaseEvent {
  event_type = 'collect_info_chosen';

  constructor(
    public event_properties: CollectInfoChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContactFormSent implements BaseEvent {
  event_type = 'contact_form_sent';

  constructor(
    public event_properties: ContactFormSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContactFormShown implements BaseEvent {
  event_type = 'contact_form_shown';

  constructor(
    public event_properties?: ContactFormShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterStatusSelected implements BaseEvent {
  event_type = 'filter_status_selected';

  constructor(
    public event_properties: FilterStatusSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GenderChosen implements BaseEvent {
  event_type = 'gender_chosen';

  constructor(
    public event_properties: GenderChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandingLocateClick implements BaseEvent {
  event_type = 'landing_locate_click';

  constructor(
    public event_properties: LandingLocateClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandingMenuClicked implements BaseEvent {
  event_type = 'landing_menu_clicked';

  constructor(
    public event_properties: LandingMenuClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandingScreenShown implements BaseEvent {
  event_type = 'landing_screen_shown';

  constructor(
    public event_properties: LandingScreenShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandingScroll implements BaseEvent {
  event_type = 'landing_scroll';

  constructor(
    public event_properties?: LandingScrollProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandingTryNowClick implements BaseEvent {
  event_type = 'landing_try_now_click';

  constructor(
    public event_properties?: LandingTryNowClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LatenightCallsChosen implements BaseEvent {
  event_type = 'latenight_calls_chosen';

  constructor(
    public event_properties: LatenightCallsChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginError implements BaseEvent {
  event_type = 'login_error';

  constructor(
    public event_properties: LoginErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginForgotClicked implements BaseEvent {
  event_type = 'login_forgot_clicked';

  constructor(
    public event_properties?: LoginForgotClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginLoginClick implements BaseEvent {
  event_type = 'login_login_click';

  constructor(
    public event_properties: LoginLoginClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginScreenShown implements BaseEvent {
  event_type = 'login_screen_shown';

  constructor(
    public event_properties?: LoginScreenShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginSuccessfully implements BaseEvent {
  event_type = 'login_successfully';

  constructor(
    public event_properties?: LoginSuccessfullyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageExit implements BaseEvent {
  event_type = 'message_exit';

  constructor(
    public event_properties?: MessageExitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageSentFailure implements BaseEvent {
  event_type = 'message_sent_failure';

  constructor(
    public event_properties: MessageSentFailureProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageSentSuccess implements BaseEvent {
  event_type = 'message_sent_success';

  constructor(
    public event_properties?: MessageSentSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessagesDeletionChosen implements BaseEvent {
  event_type = 'messages_deletion_chosen';

  constructor(
    public event_properties: MessagesDeletionChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NumberDetailsClicked implements BaseEvent {
  event_type = 'number_details_clicked';

  constructor(
    public event_properties: NumberDetailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NumberDetailsScreenShown implements BaseEvent {
  event_type = 'number_details_screen_shown';

  constructor(
    public event_properties?: NumberDetailsScreenShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NumberLocateClicked implements BaseEvent {
  event_type = 'number_locate_clicked';

  constructor(
    public event_properties: NumberLocateClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NumberNameChanged implements BaseEvent {
  event_type = 'number_name_changed';

  constructor(
    public event_properties?: NumberNameChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NumberNameDeleted implements BaseEvent {
  event_type = 'number_name_deleted';

  constructor(
    public event_properties?: NumberNameDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OauthButtonClicked implements BaseEvent {
  event_type = 'oauth_button_clicked';

  constructor(
    public event_properties: OauthButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PartnerHidingSmthChosen implements BaseEvent {
  event_type = 'partner_hiding_smth_chosen';

  constructor(
    public event_properties: PartnerHidingSmthChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaymentErrorScreenExit implements BaseEvent {
  event_type = 'payment_error_screen_exit';
}

export class PhoneHidingChosen implements BaseEvent {
  event_type = 'phone_hiding_chosen';

  constructor(
    public event_properties: PhoneHidingChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PowerscreenAffairWithStatClicked implements BaseEvent {
  event_type = 'powerscreen_affair_with_stat_clicked';

  constructor(
    public event_properties: PowerscreenAffairWithStatClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PowerscreenAffairWithStatShown implements BaseEvent {
  event_type = 'powerscreen_affair_with_stat_shown';

  constructor(
    public event_properties: PowerscreenAffairWithStatShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PowerscreenCheatingStatClicked implements BaseEvent {
  event_type = 'powerscreen_cheating_stat_clicked';

  constructor(
    public event_properties: PowerscreenCheatingStatClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PowerscreenCheatingStatShown implements BaseEvent {
  event_type = 'powerscreen_cheating_stat_shown';

  constructor(
    public event_properties: PowerscreenCheatingStatShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProfileCreationShown implements BaseEvent {
  event_type = 'profile_creation_shown';

  constructor(
    public event_properties?: ProfileCreationShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SaleScreenShown implements BaseEvent {
  event_type = 'sale_screen_shown';
}

export class SecretivePhoneChosen implements BaseEvent {
  event_type = 'secretive_phone_chosen';

  constructor(
    public event_properties: SecretivePhoneChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignupScreenShown implements BaseEvent {
  event_type = 'signup_screen_shown';

  constructor(
    public event_properties?: SignupScreenShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignupSignupClicked implements BaseEvent {
  event_type = 'signup_signup_clicked';

  constructor(
    public event_properties: SignupSignupClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SingupError implements BaseEvent {
  event_type = 'singup_error';

  constructor(
    public event_properties: SingupErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SingupSuccessfully implements BaseEvent {
  event_type = 'singup_successfully';

  constructor(
    public event_properties?: SingupSuccessfullyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpecialOfferAccepted implements BaseEvent {
  event_type = 'special_offer_accepted';

  constructor(
    public event_properties: SpecialOfferAcceptedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpecialOfferRejected implements BaseEvent {
  event_type = 'special_offer_rejected';

  constructor(
    public event_properties: SpecialOfferRejectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpecialOfferShown implements BaseEvent {
  event_type = 'special_offer_shown';

  constructor(
    public event_properties: SpecialOfferShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionClosed implements BaseEvent {
  event_type = 'subscription_closed';

  constructor(
    public event_properties: SubscriptionClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionContinueClicked implements BaseEvent {
  event_type = 'subscription_continue_clicked';

  constructor(
    public event_properties: SubscriptionContinueClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionErrorShown implements BaseEvent {
  event_type = 'subscription_error_shown';
}

export class SubscriptionErrorTryAgainClicked implements BaseEvent {
  event_type = 'subscription_error_try_again_clicked';
}

export class SubscriptionShown implements BaseEvent {
  event_type = 'subscription_shown';

  constructor(
    public event_properties: SubscriptionShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionSubscribeClicked implements BaseEvent {
  event_type = 'subscription_subscribe_clicked';

  constructor(
    public event_properties: SubscriptionSubscribeClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionSuccessShown implements BaseEvent {
  event_type = 'subscription_success_shown';

  constructor(
    public event_properties: SubscriptionSuccessShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuspiciousActivityChosen implements BaseEvent {
  event_type = 'suspicious_activity_chosen';

  constructor(
    public event_properties: SuspiciousActivityChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TimeOutChosen implements BaseEvent {
  event_type = 'time_out_chosen';

  constructor(
    public event_properties: TimeOutChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebAttribution implements BaseEvent {
  event_type = 'web_attribution';

  constructor(
    public event_properties: WebAttributionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebAttributionUpdate implements BaseEvent {
  event_type = 'web_attribution_update';

  constructor(
    public event_properties: WebAttributionUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WelcomeContinueClicked implements BaseEvent {
  event_type = 'welcome_continue_clicked';

  constructor(
    public event_properties: WelcomeContinueClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WelcomeShown implements BaseEvent {
  event_type = 'welcome_shown';

  constructor(
    public event_properties?: WelcomeShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WhereYourPartnerChosen implements BaseEvent {
  event_type = 'where_your_partner_chosen';

  constructor(
    public event_properties: WhereYourPartnerChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WhereaboutsLyingChosen implements BaseEvent {
  event_type = 'whereabouts_lying_chosen';

  constructor(
    public event_properties: WhereaboutsLyingChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * account_logout_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/account_logout_clicked)
   *
   * **Вийшов з аккаунту**
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  accountLogoutClicked(
    properties?: AccountLogoutClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountLogoutClicked(properties), options);
  }

  /**
   * account_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/account_screen_shown)
   *
   * віджигається на головному екрані акаунту
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  accountScreenShown(
    properties?: AccountScreenShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountScreenShown(properties), options);
  }

  /**
   * apple_pay_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/apple_pay_button_clicked)
   *
   * Користувач клікнув на кнопку Apple Pay
   *
   * @param options Amplitude event options.
   */
  applePayButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new ApplePayButtonClicked(), options);
  }

  /**
   * behavior_changes_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/behavior_changes_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про зміни в поведінці партнера/ки. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  behaviorChangesChosen(
    properties: BehaviorChangesChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new BehaviorChangesChosen(properties), options);
  }

  /**
   * button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/button_clicked)
   *
   * Віджигаємо івент button_clicked, коли користувач натискає на якусь кнопку на sale скріні.
   *
   * До івенту додаємо проперті button_name, куди передаємо що 
   *  це була за кнопка. Значення для проперті: reserved price, try now feature block, try now feedback block
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  buttonClicked(
    properties: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * checkout_continue_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/checkout_continue_clicked)
   *
   * Клік по кнопці Continue на чекауті
   *
   * @param properties The event's properties (e.g. product_id)
   * @param options Amplitude event options.
   */
  checkoutContinueClicked(
    properties: CheckoutContinueClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutContinueClicked(properties), options);
  }

  /**
   * checkout_error_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/checkout_error_shown)
   *
   * побачив error поп-ап
   *
   * @param properties The event's properties (e.g. product_id)
   * @param options Amplitude event options.
   */
  checkoutErrorShown(
    properties: CheckoutErrorShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutErrorShown(properties), options);
  }

  /**
   * checkout_error_try_again_ckicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/checkout_error_try_again_ckicked)
   *
   * клік по кнопці Try again на error поп-апі
   *
   * @param properties The event's properties (e.g. product_id)
   * @param options Amplitude event options.
   */
  checkoutErrorTryAgainCkicked(
    properties: CheckoutErrorTryAgainCkickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutErrorTryAgainCkicked(properties), options);
  }

  /**
   * checkout_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/checkout_shown)
   *
   * Показ чекауту
   *
   * @param properties The event's properties (e.g. product_id)
   * @param options Amplitude event options.
   */
  checkoutShown(
    properties: CheckoutShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutShown(properties), options);
  }

  /**
   * collect_info_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/collect_info_chosen)
   *
   * Віджигаємо цей івент, коли користувач натиснув Continue на екрані з питання про збір інформації про партнера
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  collectInfoChosen(
    properties: CollectInfoChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectInfoChosen(properties), options);
  }

  /**
   * contact_form_sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/contact_form_sent)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. subject)
   * @param options Amplitude event options.
   */
  contactFormSent(
    properties: ContactFormSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactFormSent(properties), options);
  }

  /**
   * contact_form_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/contact_form_shown)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  contactFormShown(
    properties?: ContactFormShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactFormShown(properties), options);
  }

  /**
   * filter_status_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/filter_status_selected)
   *
   * Користувач змінює фільтр сортування.
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  filterStatusSelected(
    properties: FilterStatusSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterStatusSelected(properties), options);
  }

  /**
   * gender_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/gender_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав стать. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  genderChosen(
    properties: GenderChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new GenderChosen(properties), options);
  }

  /**
   * landing_locate_click
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/landing_locate_click)
   *
   * \*\*Успішне відстеження \*\* 
   *
   *  **success {true = country_code 
   *  entered_number}  
   *  false { error_type невірний фoрмат, або текст помилки }**
   *
   * @param properties The event's properties (e.g. country_code)
   * @param options Amplitude event options.
   */
  landingLocateClick(
    properties: LandingLocateClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingLocateClick(properties), options);
  }

  /**
   * landing_menu_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/landing_menu_clicked)
   *
   * **відкрилось меню ( how it works, contact, login)**
   *
   * @param properties The event's properties (e.g. chosen_navigation_item)
   * @param options Amplitude event options.
   */
  landingMenuClicked(
    properties: LandingMenuClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingMenuClicked(properties), options);
  }

  /**
   * landing_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/landing_screen_shown)
   *
   * **Відкрився лендінг**
   *
   * @param properties The event's properties (e.g. ab_paywall)
   * @param options Amplitude event options.
   */
  landingScreenShown(
    properties: LandingScreenShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingScreenShown(properties), options);
  }

  /**
   * landing_scroll
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/landing_scroll)
   *
   * скрол лендінгу
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  landingScroll(
    properties?: LandingScrollProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingScroll(properties), options);
  }

  /**
   * landing_try_now_click
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/landing_try_now_click)
   *
   * віджигаємо цей івент, коли користувач клікає Try now на лендінг пейджі
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  landingTryNowClick(
    properties?: LandingTryNowClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingTryNowClick(properties), options);
  }

  /**
   * latenight_calls_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/latenight_calls_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про пізні дзвінки. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  latenightCallsChosen(
    properties: LatenightCallsChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new LatenightCallsChosen(properties), options);
  }

  /**
   * login_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/login_error)
   *
   * помилка логіну
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  loginError(
    properties: LoginErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginError(properties), options);
  }

  /**
   * login_forgot_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/login_forgot_clicked)
   *
   * **Натиснув на Забули пароль?**
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  loginForgotClicked(
    properties?: LoginForgotClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginForgotClicked(properties), options);
  }

  /**
   * login_login_click
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/login_login_click)
   *
   * Натиснув кнопку. Успішний/неуспішний логін 
   *
   *  success {true email} false {type ( немає такого користувача, невірний логін пароль) }
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  loginLoginClick(
    properties: LoginLoginClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginLoginClick(properties), options);
  }

  /**
   * login_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/login_screen_shown)
   *
   * **показаний екран з можливістю зайти в акк**
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  loginScreenShown(
    properties?: LoginScreenShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginScreenShown(properties), options);
  }

  /**
   * login_successfully
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/login_successfully)
   *
   * успішний логін
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  loginSuccessfully(
    properties?: LoginSuccessfullyProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginSuccessfully(properties), options);
  }

  /**
   * message_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/message_exit)
   *
   * **Користувач закрив вікно з відправкою повідомлення**
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  messageExit(
    properties?: MessageExitProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageExit(properties), options);
  }

  /**
   * message_sent_failure
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/message_sent_failure)
   *
   * користувач клікнув по кнопці Send на модалці, але ми не змогли надіслати повідомлення
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  messageSentFailure(
    properties: MessageSentFailureProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageSentFailure(properties), options);
  }

  /**
   * message_sent_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/message_sent_success)
   *
   * **Показаний екран з відправкою повідомлення+ Повідомлення відправлено на введений номер**
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  messageSentSuccess(
    properties?: MessageSentSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageSentSuccess(properties), options);
  }

  /**
   * messages_deletion_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/messages_deletion_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про видалення повідомлень/історії дзвінків. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  messagesDeletionChosen(
    properties: MessagesDeletionChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessagesDeletionChosen(properties), options);
  }

  /**
   * number_details_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/number_details_clicked)
   *
   * корисутвач клікнув в історії на номеру телефону, який шукав до цього
   *
   * @param properties The event's properties (e.g. location_id)
   * @param options Amplitude event options.
   */
  numberDetailsClicked(
    properties: NumberDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NumberDetailsClicked(properties), options);
  }

  /**
   * number_details_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/number_details_screen_shown)
   *
   * **Відкрив екран з деталями відстежуваного номеру**
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  numberDetailsScreenShown(
    properties?: NumberDetailsScreenShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new NumberDetailsScreenShown(properties), options);
  }

  /**
   * number_locate_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/number_locate_clicked)
   *
   * Натиснув відстежити номер, якщо успішне - відкривається вікно з відправкою повідомленняsuccess = true{ entered_number, country code}
   *
   * @param properties The event's properties (e.g. country_code)
   * @param options Amplitude event options.
   */
  numberLocateClicked(
    properties: NumberLocateClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NumberLocateClicked(properties), options);
  }

  /**
   * number_name_changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/number_name_changed)
   *
   * Користувач змінив назву номера.
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  numberNameChanged(
    properties?: NumberNameChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NumberNameChanged(properties), options);
  }

  /**
   * number_name_deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/number_name_deleted)
   *
   * Користувач видалив номер.
   *
   * @param properties The event's properties (e.g. mode)
   * @param options Amplitude event options.
   */
  numberNameDeleted(
    properties?: NumberNameDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NumberNameDeleted(properties), options);
  }

  /**
   * oauth_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/oauth_button_clicked)
   *
   * користувач клікає на кнопку Continue with Google/Continue with Facebook на флоу логіну або створення акаунту
   *
   * у проперті provider передає через що саме намагється продовжити користувач (google/fb)
   *
   * @param properties The event's properties (e.g. provider)
   * @param options Amplitude event options.
   */
  oauthButtonClicked(
    properties: OauthButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OauthButtonClicked(properties), options);
  }

  /**
   * partner_hiding_smth_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/partner_hiding_smth_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про приховування чогось партнером. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  partnerHidingSmthChosen(
    properties: PartnerHidingSmthChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new PartnerHidingSmthChosen(properties), options);
  }

  /**
   * payment_error_screen_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/payment_error_screen_exit)
   *
   * **Закрив екран після помилки оплати**
   *
   * @param options Amplitude event options.
   */
  paymentErrorScreenExit(
    options?: EventOptions,
  ) {
    return this.track(new PaymentErrorScreenExit(), options);
  }

  /**
   * phone_hiding_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/phone_hiding_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про "приховування" телефону, коли ви поруч. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  phoneHidingChosen(
    properties: PhoneHidingChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new PhoneHidingChosen(properties), options);
  }

  /**
   * powerscreen_affair_with_stat_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/powerscreen_affair_with_stat_clicked)
   *
   * Віджигаємо цей івент, коли користувач натискає Continue  
   *  на другому екрані зі статистикою. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. question_number)
   * @param options Amplitude event options.
   */
  powerscreenAffairWithStatClicked(
    properties: PowerscreenAffairWithStatClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PowerscreenAffairWithStatClicked(properties), options);
  }

  /**
   * powerscreen_affair_with_stat_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/powerscreen_affair_with_stat_shown)
   *
   * Віджигаємо цей івент, коли користувач потріпляє на другий 
   *  екран зі статистикою. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. question_number)
   * @param options Amplitude event options.
   */
  powerscreenAffairWithStatShown(
    properties: PowerscreenAffairWithStatShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new PowerscreenAffairWithStatShown(properties), options);
  }

  /**
   * powerscreen_cheating_stat_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/powerscreen_cheating_stat_clicked)
   *
   * Віджигаємо цей івент, коли користувач натискає Continue  
   *  на першому екрані зі статистикою. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. question_number)
   * @param options Amplitude event options.
   */
  powerscreenCheatingStatClicked(
    properties: PowerscreenCheatingStatClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PowerscreenCheatingStatClicked(properties), options);
  }

  /**
   * powerscreen_cheating_stat_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/powerscreen_cheating_stat_shown)
   *
   * Віджигаємо цей івент, коли користувач потравляє на перший 
   *  екран зі статистикою. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. question_number)
   * @param options Amplitude event options.
   */
  powerscreenCheatingStatShown(
    properties: PowerscreenCheatingStatShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new PowerscreenCheatingStatShown(properties), options);
  }

  /**
   * profile_creation_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/profile_creation_shown)
   *
   * Віджигаємо цей івент, коли користувач потрапляє на сторінку 
   *  з аналітикою "створення акаунту". 
   *
   *  До івенту додаємо наступні івент property: 
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. quiz_type)
   * @param options Amplitude event options.
   */
  profileCreationShown(
    properties?: ProfileCreationShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProfileCreationShown(properties), options);
  }

  /**
   * sale_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/sale_screen_shown)
   *
   * Віджигаємо цей івент, коли користувач потрапляє на сторінку з описом продукту й кнопками, що ведуть на пейвол.
   *
   * @param options Amplitude event options.
   */
  saleScreenShown(
    options?: EventOptions,
  ) {
    return this.track(new SaleScreenShown(), options);
  }

  /**
   * secretive_phone_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/secretive_phone_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про "приховування" телефону. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  secretivePhoneChosen(
    properties: SecretivePhoneChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecretivePhoneChosen(properties), options);
  }

  /**
   * signup_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/signup_screen_shown)
   *
   * **показаний екран з можливістю створити акк**
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  signupScreenShown(
    properties?: SignupScreenShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignupScreenShown(properties), options);
  }

  /**
   * signup_signup_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/signup_signup_clicked)
   *
   * Натиснув кнопку. Успішна/неуспішна реєстрація 
   *
   *  success = true { email} success = false { type = ненадійний пароль, імейл, інше}
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  signupSignupClicked(
    properties: SignupSignupClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignupSignupClicked(properties), options);
  }

  /**
   * singup_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/singup_error)
   *
   * Віджигаємо цей івент, якщо в користувача помилка  
   *  реєстрації. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - reason, куди передаємо помилку при реєстрації 
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  singupError(
    properties: SingupErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new SingupError(properties), options);
  }

  /**
   * singup_successfully
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/singup_successfully)
   *
   * Віджигаємо цей івент, якщо в користувач упішно ввів  
   *  особисті дані. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. landing_type)
   * @param options Amplitude event options.
   */
  singupSuccessfully(
    properties?: SingupSuccessfullyProperties,
    options?: EventOptions,
  ) {
    return this.track(new SingupSuccessfully(properties), options);
  }

  /**
   * special_offer_accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/special_offer_accepted)
   *
   * Юзер прийняв офер і для нього відкриватиметься пейвол
   *
   * * offer_name = initial/followup
   *
   * @param properties The event's properties (e.g. offer_name)
   * @param options Amplitude event options.
   */
  specialOfferAccepted(
    properties: SpecialOfferAcceptedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpecialOfferAccepted(properties), options);
  }

  /**
   * special_offer_rejected
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/special_offer_rejected)
   *
   * Юзер відмовився від пропозиції на одному з екранів добивашки
   *
   * * **offer_name = initial/followup**
   *
   * @param properties The event's properties (e.g. offer_name)
   * @param options Amplitude event options.
   */
  specialOfferRejected(
    properties: SpecialOfferRejectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpecialOfferRejected(properties), options);
  }

  /**
   * special_offer_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/special_offer_shown)
   *
   * Показується один з скрінів добивашки
   *
   * * offer_name - перший чи другий (який з'являється після відхилення першого) офер; initial/followup
   *
   * @param properties The event's properties (e.g. offer_name)
   * @param options Amplitude event options.
   */
  specialOfferShown(
    properties: SpecialOfferShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpecialOfferShown(properties), options);
  }

  /**
   * subscription_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/subscription_closed)
   *
   * **Користувач закрив екран з оплатою**
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  subscriptionClosed(
    properties: SubscriptionClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionClosed(properties), options);
  }

  /**
   * subscription_continue_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/subscription_continue_clicked)
   *
   * клік по кнопці контінує в мобільній версії. 
   *
   *  Важливо! Це не клік по кнопці Start trial
   *
   * @param properties The event's properties (e.g. plan)
   * @param options Amplitude event options.
   */
  subscriptionContinueClicked(
    properties: SubscriptionContinueClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionContinueClicked(properties), options);
  }

  /**
   * subscription_error_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/subscription_error_shown)
   *
   * **Неуспішна оплата**
   *
   * @param options Amplitude event options.
   */
  subscriptionErrorShown(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionErrorShown(), options);
  }

  /**
   * subscription_error_try_again_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/subscription_error_try_again_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  subscriptionErrorTryAgainClicked(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionErrorTryAgainClicked(), options);
  }

  /**
   * subscription_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/subscription_shown)
   *
   * **показаний екран з оплатою**
   *
   * @param properties The event's properties (e.g. button)
   * @param options Amplitude event options.
   */
  subscriptionShown(
    properties: SubscriptionShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionShown(properties), options);
  }

  /**
   * subscription_subscribe_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/subscription_subscribe_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. plan)
   * @param options Amplitude event options.
   */
  subscriptionSubscribeClicked(
    properties: SubscriptionSubscribeClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSubscribeClicked(properties), options);
  }

  /**
   * subscription_success_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/subscription_success_shown)
   *
   * **Успішна оплата**
   *
   * @param properties The event's properties (e.g. payment_provider)
   * @param options Amplitude event options.
   */
  subscriptionSuccessShown(
    properties: SubscriptionSuccessShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSuccessShown(properties), options);
  }

  /**
   * suspicious_activity_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/suspicious_activity_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про підозрілу активність. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  suspiciousActivityChosen(
    properties: SuspiciousActivityChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuspiciousActivityChosen(properties), options);
  }

  /**
   * time_out_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/time_out_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про час з "друзями". 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  timeOutChosen(
    properties: TimeOutChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new TimeOutChosen(properties), options);
  }

  /**
   * web_attribution
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/web_attribution)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. browser)
   * @param options Amplitude event options.
   */
  webAttribution(
    properties: WebAttributionProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebAttribution(properties), options);
  }

  /**
   * web_attribution_update
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/web_attribution_update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. browser)
   * @param options Amplitude event options.
   */
  webAttributionUpdate(
    properties: WebAttributionUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebAttributionUpdate(properties), options);
  }

  /**
   * welcome_continue_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/welcome_continue_clicked)
   *
   * Користувач натиснув Continue на welcome екрані воронки 
   *
   * До івенту додаємо пропеті **quiz_type**, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. quiz_type)
   * @param options Amplitude event options.
   */
  welcomeContinueClicked(
    properties: WelcomeContinueClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WelcomeContinueClicked(properties), options);
  }

  /**
   * welcome_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/welcome_shown)
   *
   * Користувач потрапив на welcome екран воронки
   *
   * До івенту додаємо пропеті **quiz_type**, куди передаємо 
   *  ім'я квіза
   *
   * @param properties The event's properties (e.g. quiz_type)
   * @param options Amplitude event options.
   */
  welcomeShown(
    properties?: WelcomeShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new WelcomeShown(properties), options);
  }

  /**
   * where_your_partner_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/where_your_partner_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про місце перебування партнера/ки. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі 
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  whereYourPartnerChosen(
    properties: WhereYourPartnerChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new WhereYourPartnerChosen(properties), options);
  }

  /**
   * whereabouts_lying_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB%202.0/events/main/latest/whereabouts_lying_chosen)
   *
   * Віджигаємо цей івент, коли користувач обрав відповідь на  
   *  питання про брехню щодо місцезнаходження. 
   *
   *  До івенту додаємо наступні івент property: 
   *  - answer, куди передаємо відповідь на питання 
   *  - question_number, куди передаємо яке це питання в квізі  
   *  - quiz_type, куди передаємо ім'я квіза
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  whereaboutsLyingChosen(
    properties: WhereaboutsLyingChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new WhereaboutsLyingChosen(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
