import { Button, Dialog, styled } from "@mui/material";

export const StyledButtonAccept = styled(Button)(({ theme }) => ({
    fontFamily: theme.typography.dmSansBody1Regular.fontFamily,
    fontWeight: 600,
    borderRadius: "16px",
    width: "357px",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.brightGreen,
    textTransform: "uppercase",
    [theme.breakpoints.down("lmd")]: {
        width: "100%",
    },
}));

export const StyledButtonDecline = styled(StyledButtonAccept)({
    backgroundColor: "rgba(95, 203, 57, 0.20)",
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, .9)",
    },
    "& .MuiPaper-root": {
        borderRadius: "32px",
        overflow: "hidden",
        maxWidth: "954px",
        background: "transparent",
    },
    [theme.breakpoints.down("lmd")]: {
        "& .MuiPaper-root": {
            maxWidth: "calc(100% - 32px)",
            marginRight: "16px",
            marginLeft: "16px",
        },
    },
}));
