import { FC } from "react";
import { SpeedDial, SpeedDialIcon } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useBoolean } from "hooks/useBoolean";
import { DevToolsContext } from "./context";
import features from "./features";

const DevToolsWidget: FC = () => {
    const [isExpanded, setIsExpanded] = useBoolean(false);
    const [isDevToolsShown, setDevToolsVisibility] = useBoolean(true);

    return (
        <DevToolsContext.Provider value={{ isExpanded, isDevToolsShown, setDevToolsVisibility }}>
            <SpeedDial
                sx={(theme) => ({
                    position: "fixed",
                    bottom: 16,
                    right: 16,
                    "& .MuiSpeedDial-fab, .MuiSpeedDial-fab:hover": {
                        backgroundColor: theme.palette.brandColors.brandGreen,
                    },
                    "& .MuiSpeedDialAction-staticTooltipLabel": {
                        color: theme.palette.text.black,
                        whiteSpace: "nowrap",
                    },
                })}
                icon={<SpeedDialIcon />}
                ariaLabel="devtools"
                onOpen={setIsExpanded.on}
                onClose={setIsExpanded.off}
                hidden={!isDevToolsShown}
            >
                {isDevToolsShown && features.map((Feature, index) => <Feature key={`feature-${index}`} />)}
            </SpeedDial>
        </DevToolsContext.Provider>
    );
};

export default observer(DevToolsWidget);
