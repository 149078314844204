import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PROJECT_NAME } from "constants/env.const";
import { saveToSessionStorage } from "services/storage";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { getPaymentSubscriptionPlans, IGetPaymentSubscriptionPlansParams } from "http-client/payment.client";
import { AppRoutes } from "enums/routes.enum";
import { PaymentProvider } from "enums/payment.enum";
import { IxopayFormData } from "../types";

interface Props {
    planMatchCallback?: (plans: Record<string, any>[]) => boolean;
    extraDeclineQuery?: Record<string, string>;
}

export const usePaymentLogic = (options?: Props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [planData, setPlanData] = useState<Record<string, any> | null>(null);
    const [ixopayFormData, setIxopayFormData] = useState<IxopayFormData | null>(null);
    const [allPlans, setAllPlans] = useState<any[] | undefined>(undefined);
    const { webSessionLoading, user, userWebSession, userProfile } = useUserStore();
    const userData = userWebSession?.session?.user;
    const { ampliIDLoading, amplitudeUserId } = useAppData();

    const findRequiredPlan = (plans: any[]) => {
        const defaultFinder = (plan) =>
            plan.price === 49.99 &&
            plan.trialPrice === 4.99 &&
            (!plan.trialPriceVat || plan.trialPriceVat === 4.99) &&
            (!plan.priceVat || plan.priceVat === 49.99);

        return plans.find(options?.planMatchCallback ?? defaultFinder);
    };

    useEffect(() => {
        (async () => {
            /* Set isAlternate flag only once to avoid bugs */
            if (webSessionLoading) return;

            /* Get subscription plan from server only after payout type is chosen */
            const params: IGetPaymentSubscriptionPlansParams = {
                project: PROJECT_NAME,
                provider: PaymentProvider.IXOPAY,
                countryCode: "US", // userData?.countryCode,
                isTest: false,
            };

            const plans = await getPaymentSubscriptionPlans(params);

            if (plans && Array.isArray(plans)) {
                setAllPlans(plans);
                const ixopayPlan = findRequiredPlan(plans);
                setPlanData(ixopayPlan);
                saveToSessionStorage("selected-plan", JSON.stringify(ixopayPlan));
            }
        })();
    }, [webSessionLoading]);

    useEffect(() => {
        const declineQuery = {
            usid: amplitudeUserId,
            ...options?.extraDeclineQuery,
            provider: "ixopay",
        };
        const applepayDeclineQuery = { ...declineQuery, provider: "applepay" };

        const _ixopayFormData: IxopayFormData = {
            successPage: AppRoutes.SUCCESS,
            successQuery: { usid: amplitudeUserId, provider: "ixopay" },
            applepaySuccessQuery: { usid: amplitudeUserId, provider: "applepay" },
            declinePage: AppRoutes.DECLINE,
            declineQuery,
            applepayDeclineQuery,
            backPage: pathname,
            backQuery: { usid: amplitudeUserId },
            showPaymentPlans: false,
            plan: planData,
        };

        setIxopayFormData(_ixopayFormData);

        /* Required data is still loading */
        if (webSessionLoading || ampliIDLoading) return;

        /* Loading is finished but required data is not found */
        if (!webSessionLoading && !ampliIDLoading && (!userData?.id || !amplitudeUserId || !user?.email)) {
            navigate(AppRoutes.HOME);
            return;
        }
    }, [webSessionLoading, ampliIDLoading, amplitudeUserId, planData, userData, userProfile]);

    return { planData, ixopayFormData, allPlans };
};
