export type IxopayFormData = Record<string, any>;

export interface IxopayFormProps {
    formDisabled?: boolean;
    data: IxopayFormData | null;
    paypalPlan?: Record<string, any> | null;
}

export enum PaymentButtonTitleVariants {
    CONTINUE = "Continue",
    START_TRIAL = "Start trial",
}
