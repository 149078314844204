import { styled } from "@mui/material";
import { textFieldProps } from "components/form-components/TextField/TextField";
import { IxopayFormTextField } from "./IxopayFormTextField";
import { themeColors } from "constants/colors.const";

export const LocationMapTextField = styled(IxopayFormTextField)(() => ({
    ...textFieldProps,
    "& .MuiInputBase-root": {
        borderRadius: 0,
        border: "none",
        borderBottom: `1px solid ${themeColors.background.borderLightGrey}`,
        backgroundColor: "transparent !important",
        "& input": {
            height: "46px",
            color: themeColors.text.white,
            fontSize: "16px",
            padding: "1rem 0 .5rem",
            "&::placeholder": {
                fontSize: "16px",
                color: "rgba(255,255,255, .35)",
            },
        },
        "&.Mui-error": {
            color: themeColors.text.black,
            "& input": {
                color: themeColors.text.black,
            },
        },
    },
    "& input": {
        backgroundColor: "transparent",
        color: themeColors.text.fullBlack,
        fontSize: "20px",
    },
    "& fieldset": {
        backgroundColor: "transparent",
    },
    "&.Mui-focused": {
        color: themeColors.text.white,
        "& input": {
            color: themeColors.text.white,
        },
    },
    "&.Mui-focused:not(.Mui-focused.Mui-error)": {
        color: themeColors.text.white,
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${themeColors.background.grey} inset`,
        WebkitTextFillColor: themeColors.text.white,
        color: themeColors.text.white,
    },
}));
