export const planVariants = [
    {
        title: "Monthly Plan",
        subtitle: "$14.99",
        specialPrice: "$0.49",
        specialPriceAddOn: "/ per day",
        badgeTitle: "Save 70%",
        accentColor: "#72789A",
        activePriceColor: "#9FB0D6",
        trialPriceVat: 14.99,
        priceVat: 49.99,
        oldPrice: 49.99,
    },
    {
        title: "24h Trial",
        subtitle: "of full access",
        specialPrice: "$4.99",
        specialPriceAddOn: "only",
        badgeTitle: "Most popular",
        accentColor: "#5FCB39",
        activePriceColor: "#5FCB39",
        priceVat: 49.99,
        trialPriceVat: 4.99,
        oldPrice: 9.99,
    },
];
