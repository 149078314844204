import { FC } from "react";
import { DialogProps, Stack, Typography } from "@mui/material";
import { useTranslation } from "hooks/useTranslation";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { StyledButtonAccept, StyledButtonDecline, StyledDialog } from "../shared.styled";
import { ExtraOffersType } from "../../ExtraOffers.types";
import { useOfferEvents } from "../../hooks";

interface Props extends DialogProps {
    onAccept?: () => void;
    onDecline?: () => void;
}

export const PrimaryOfferModal: FC<Props> = ({ open, onAccept, onDecline }) => {
    const {
        t,
        detect: { isFrenchLanguage, isPortugueseLanguage, isSpanishLanguage, isVietnameseLanguage, isGermanLanguage },
    } = useTranslation();
    const { isTablet } = useDeviceDetect();
    const { handleAccept, handleDecline } = useOfferEvents({ offerName: ExtraOffersType.INITIAL, open });

    const isLargeContentContentLanguage = isFrenchLanguage || isPortugueseLanguage || isSpanishLanguage || isVietnameseLanguage || isGermanLanguage;

    const handleModalAccept = () => handleAccept(onAccept);
    const handleModalDecline = () => handleDecline(onDecline);

    return (
        <>
            <StyledDialog
                open={open}
                onClose={handleModalDecline}
                sx={{
                    "& .MuiPaper-root": {
                        maxWidth: { xs: "calc(100% - 32px)", lmd: isLargeContentContentLanguage ? "1040px" : "954px" },
                    },
                }}
            >
                <img src={isTablet ? "/images/discount-90-bg-mob.png" : "/images/discount-90-bg.png"} alt="discount" />
                <Stack
                    sx={{
                        position: "absolute",
                        left: { xs: "unset", lmd: "49px" },
                        padding: { xs: "0 16px", lmd: "55px 0" },
                        maxWidth: { xs: "100%", lmd: "43%" },
                        bottom: { xs: "12px", lmd: "unset" },
                    }}
                >
                    <Typography
                        variant="dmSansH4Medium"
                        sx={{
                            color: "text.secondary",
                            mb: { xs: "24px", lmd: "19px" },
                            textAlign: "center",
                            fontSize: { xs: isLargeContentContentLanguage ? "24px" : "34px", lmd: "34px" },
                            lineHeight: 1.2,
                        }}
                    >
                        {t("Get 90% OFF for 24 hour trial today!")}
                    </Typography>
                    <Typography
                        variant="dmSansH5Regular"
                        sx={{
                            color: "#dcdcdc",
                            mb: "21px",
                            textAlign: "center",
                            fontSize: { xs: "20px", lmd: "23px" },
                            fontWeight: { xs: 500, lmd: 400 },
                            lineHeight: "normal",
                        }}
                    >
                        {t("Unlock every feature and see what's possible")}
                    </Typography>
                    <StyledButtonAccept variant="themePrimarySmall" onClick={handleModalAccept} sx={{ mb: "15px" }}>
                        {t("claim now")}
                    </StyledButtonAccept>
                    <StyledButtonDecline variant="themePrimarySmall" onClick={handleModalDecline}>
                        {t("no thanks")}
                    </StyledButtonDecline>
                </Stack>
            </StyledDialog>
        </>
    );
};
