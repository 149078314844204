import { ReactComponent as Icon24H } from "assets/icons/24h.svg";
import { ReactComponent as CoverageIcon } from "assets/icons/global-coverage.svg";
import { ReactComponent as OSIcon } from "assets/icons/operation-systems.svg";
import { ReactComponent as PreciseLocationIcon } from "assets/icons/precise-location.svg";
import { ReactComponent as CursorIcon } from "assets/icons/search-cursor.svg";

export const benefits = [
    {
        text: "24 Trackings Per Day: Always stay updated",
        icon: <Icon24H />,
    },
    {
        text: "Precise Location: Accuracy up to 5 meters",
        icon: <PreciseLocationIcon />,
    },
    {
        text: "Global Coverage: Reliable tracking worldwide",
        icon: <CoverageIcon />,
    },
    {
        text: "Search History: Review past locations",
        icon: <CursorIcon />,
    },
    {
        text: "Cross-Platform Compatibility: Works on IOS and Android",
        icon: <OSIcon />,
    },
];

export const benefitsV3 = [
    ...benefits.slice(0, benefits.length - 1),
    {
        text: "Compatible with iOS, Android & Web",
        icon: <OSIcon />,
    },
];
