import { IxopayFormTextField } from "../../../shared/components";
import { styled } from "@mui/material";
import { textFieldProps } from "components/form-components/TextField/TextField";

export const StyledTextInput = styled(IxopayFormTextField)(({ theme }) => ({
    ...textFieldProps,
    "& .MuiInputBase-root": {
        borderColor: theme.palette.background.whiteOpacity4,
        backgroundColor: theme.palette.text.primary,
    },
    "& input": {
        backgroundColor: theme.palette.text.primary,
        fontSize: "20px",
    },
}));
