import React, { ReactElement } from "react";
import { FormControl as MUIFormControl, FormHelperText as MUIFormHelperText } from "@mui/material";

import { FormControlProps } from "./types";

export const FormControl: React.FC<FormControlProps> = ({
    children,
    className,
    error,
    disabled,
    errorMessage,
    helperText,
    required,
    variant = "outlined",
    disableHelperText = false,
}): ReactElement => {
    const hasError = error || errorMessage != null;
    return (
        <MUIFormControl fullWidth variant={variant} className={className}>
            {children}
            {!disableHelperText && (errorMessage || helperText) ? (
                <MUIFormHelperText variant={variant} component="div" required={required} disabled={disabled} error={hasError}>
                    {errorMessage ?? helperText}
                </MUIFormHelperText>
            ) : null}
        </MUIFormControl>
    );
};
