import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ampli } from "services/ampli";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { CarrierData } from "http-client/phone.client";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { LandingType } from "constants/landing-variants.const";
import { AppRoutes } from "enums/routes.enum";

const searchCards = [
    { label: "Carrier", value: "", blurred: false, defined: false },
    { label: "Country", value: "", blurred: false, defined: false },
    { label: "City", value: "", blurred: false, defined: false },
    { label: "Location search card label", value: "", blurred: true, defined: false },
];
const getLocatedCards = ({ carrier, country, city = "City" }: CarrierData) => [
    { label: "Carrier", value: carrier, blurred: false, defined: false },
    { label: "Country", value: country, blurred: false, defined: false },
    { label: "City", value: city, blurred: true, defined: false },
    { label: "Location search card label", value: "", blurred: false, defined: true },
];

const searchStatuses = [
    {
        icon: "/icons/sending-request.svg",
        statusTitle: "Sending a request...",
        color: "#BF5252",
        bgColor: "rgba(191, 82, 82, 0.1)",
    },
    {
        icon: "/icons/download-yellow.svg",
        statusTitle: "Data processing...",
        color: "#D8B349",
        bgColor: "rgba(216, 179, 73, 0.1)",
    },
    {
        icon: "/icons/download-yellow.svg",
        statusTitle: "Data processing...",
        color: "#D8B349",
        bgColor: "rgba(216, 179, 73, 0.1)",
    },
    {
        icon: "/icons/checked-green.svg",
        statusTitle: "Verified!",
        color: "#58B287",
        bgColor: "rgba(88, 178, 135, 0.1)",
    },
];

const mapPaywallSearchStatus = {
    icon: "/icons/checked-green.svg",
    statusTitle: "Located",
    color: "#58B287",
    bgColor: "rgba(88, 178, 135, 0.1)",
};

const mapZoomRatio = 3;

const staticCards = [
    {
        label: "Carrier",
        value: "",
        blurred: false,
        defined: false,
    },
    {
        label: "Country",
        value: "",
        blurred: false,
        defined: false,
    },
    {
        label: "City",
        value: "",
        blurred: false,
        defined: true,
    },
    {
        label: "Location search card label",
        value: "",
        blurred: false,
        defined: true,
    },
];

export const useFakeSearch = ({
    withMap,
    disableRedirect,
    disableClickEventSend,
}: { withMap?: boolean; disableRedirect?: boolean; disableClickEventSend?: boolean } = {}) => {
    const navigate = useNavigate();
    const { searchPhoneNumber, fetchPhoneCarrier, searchCarrierData, abTestData } = useAppData();
    const abLandingTest = abTestData?.["abTestLanding"];
    const [fakePhoneLocated, setFakePhoneLocated] = useState(false);
    const [searchLocation, setSearchLocation] = useState<Record<string, number> | null>(null);
    const [searchStatus, setSearchStatus] = useState(searchStatuses[0]);
    const [mapZoom, setMapZoom] = useState(mapZoomRatio);
    const [cards, setCards] = useState([searchCards[0]]);
    const [staticSearchCards, setStaticSearchCards] = useState(staticCards);
    const { user } = useUserStore();
    const [searchParams] = useSearchParams();
    const sourceLandingSearchParam = searchParams.get("landing") as LandingType | null;
    const isLoggedIn = !!user?.email;

    const getLandingParam = () => {
        if (!sourceLandingSearchParam) {
            return;
        }

        if (sourceLandingSearchParam === LandingType.DEFAULT) {
            return LandingType.DEFAULT;
        } else {
            return `[${abLandingTest}] ${sourceLandingSearchParam}`;
        }
    };
    const parsedLandingType = getLandingParam();

    useEffect(() => {
        if (disableRedirect) return;

        let timeoutId;
        if (isLoggedIn) {
            timeoutId = setTimeout(() => {
                navigate(AppRoutes.ACCOUNT);
            }, 500);
        } else if (!searchPhoneNumber) {
            timeoutId = setTimeout(() => {
                navigate(AppRoutes.HOME);
            }, 500);
        }

        return () => {
            if (timeoutId) {
                return clearTimeout(timeoutId);
            }
        };
    }, [isLoggedIn, searchPhoneNumber]);

    const getLocationByPhone = useCallback(async () => {
        if (!searchPhoneNumber) {
            return;
        }

        try {
            const carrierData = await fetchPhoneCarrier();
            setStaticSearchCards((prevCards) => [
                { ...staticSearchCards[0], value: carrierData?.carrier || "" },
                { ...staticSearchCards[1], value: carrierData?.country || "" },
                ...prevCards.slice(2),
            ]);
            if (withMap && carrierData?.coordinates) {
                setSearchLocation(carrierData.coordinates);
            }
            if (!disableClickEventSend) {
                const params = {
                    success: true,
                    country_code: carrierData?.country || "",
                    entered_number: carrierData?.phone || "",
                    error_type: "",
                    landing_type: parsedLandingType,
                };
                handleAmpliLoaded(() => ampli.landingLocateClick(params));
            }

            return carrierData;
        } catch (err: any) {
            if (!disableClickEventSend) {
                const params = {
                    success: false,
                    error_type: err.message,
                    country_code: "",
                    entered_number: "",
                    landing_type: parsedLandingType,
                };
                handleAmpliLoaded(() => ampli.landingLocateClick(params));
            }
        }
    }, [searchPhoneNumber]);

    useEffect(() => {
        getLocationByPhone();
    }, []);

    useEffect(() => {
        setFakePhoneLocated(false);

        const interval = setInterval(() => {
            if (!searchCarrierData) return;
            if (cards.length === searchCards.length && cards[cards.length - 1].defined) {
                setFakePhoneLocated(true);
                clearInterval(interval);
                return;
            }
            if (cards.length > 1) {
                setMapZoom(mapZoomRatio * cards.length);
            }
            setSearchStatus(searchStatuses[cards.length - 1]);
            const locatedCards = getLocatedCards(searchCarrierData);
            const locatedCard = locatedCards[cards.length - 1];
            const searchCard = searchCards?.[cards.length];
            const newCards = [...cards.slice(0, cards.length - 1), locatedCard];
            if (searchCard) {
                newCards.push(searchCard);
            }
            setCards(newCards);
        }, 2000);

        return () => clearInterval(interval);
    }, [searchCarrierData, cards]);

    return {
        mapPaywallSearchStatus,
        searchStatuses,
        fakePhoneLocated,
        searchLocation,
        searchStatus,
        mapZoom,
        cards,
        staticSearchCards,
    };
};
