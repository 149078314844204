export const loadApplePayScript = () => {
    return new Promise<void>((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js";
        script.id = "apple-pay-script";
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error("Failed to load Apple Pay script"));
        document.body.appendChild(script);
    });
};
