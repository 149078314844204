export const VATCountries = [
    {
        name: "Austria",
        code: "AT",
        vatRate: 20,
    },
    {
        name: "Belgium",
        code: "BE",
        vatRate: 21,
    },
    {
        name: "Bulgaria",
        code: "BG",
        vatRate: 20,
    },
    {
        name: "Croatia",
        code: "HR",
        vatRate: 25,
    },
    {
        name: "Cyprus",
        code: "CY",
        vatRate: 19,
    },
    {
        name: "Czech Republic",
        code: "CZ",
        vatRate: 21,
    },
    {
        name: "Denmark",
        code: "DK",
        vatRate: 25,
    },
    {
        name: "Estonia",
        code: "EE",
        vatRate: 20,
    },
    {
        name: "Finland",
        code: "FI",
        vatRate: 24,
    },
    {
        name: "France",
        code: "FR",
        vatRate: 20,
    },
    {
        name: "Germany",
        code: "DE",
        vatRate: 19,
    },
    {
        name: "Greece",
        code: "EL",
        vatRate: 24,
    },
    {
        name: "Hungary",
        code: "HU",
        vatRate: 27,
    },
    {
        name: "Ireland",
        code: "IE",
        vatRate: 23,
    },
    {
        name: "Italy",
        code: "IT",
        vatRate: 22,
    },
    {
        name: "Latvia",
        code: "LV",
        vatRate: 21,
    },
    {
        name: "Lithuania",
        code: "LT",
        vatRate: 21,
    },
    {
        name: "Luxembourg",
        code: "LU",
        vatRate: 17,
    },
    {
        name: "Malta",
        code: "MT",
        vatRate: 18,
    },
    {
        name: "Netherlands",
        code: "NL",
        vatRate: 21,
    },
    {
        name: "Poland",
        code: "PL",
        vatRate: 23,
    },
    {
        name: "Portugal",
        code: "PT",
        vatRate: 23,
    },
    {
        name: "Romania",
        code: "RO",
        vatRate: 19,
    },
    {
        name: "Slovakia",
        code: "SK",
        vatRate: 20,
    },
    {
        name: "Slovenia",
        code: "SI",
        vatRate: 22,
    },
    {
        name: "Spain",
        code: "ES",
        vatRate: 21,
    },
    {
        name: "Sweden",
        code: "SE",
        vatRate: 25,
    },
    {
        name: "United Kingdom",
        code: "UK",
        vatRate: 20,
    },
    {
        name: "Great Britain",
        code: "GB",
        vatRate: 20,
    },
];
