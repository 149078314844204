import { PaywallVariants } from "enums/PaywallVariants";
import PaymentFormDefault from "./default";
import PaymentFormWithMap from "./locationMap";
import PaymentFormWithMapWithContinueStep from "./locationMapV3";

export const PaymentForm = {
    [PaywallVariants.default]: PaymentFormDefault,
    [PaywallVariants.MapV1]: PaymentFormWithMap,
    [PaywallVariants.MapV3]: PaymentFormWithMapWithContinueStep,
};
