import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";

import { ixopayFormSchema } from "schemas/ixopayForm";
import FormControl from "components/form-components/FormControl";
import { IxopayFormSpy, PaypalButton } from "components/Payment/shared/components";
import Form from "components/form-components/Form";
import { FormContainer } from "components/shared";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { formatterCurrencyUS } from "services/numberFormat";
import { isProductionHost } from "helpers/isProductionHost";
import { PAYMENT_PLAN_TYPE } from "constants/env.const";
import { VATCountries } from "constants/VATCountries.const";
import { themeColors } from "constants/colors.const";
import { PaymentPlanType } from "enums/payment.enum";

import { DisclaimersFormBottomBlock, StyledTextInput } from "./components";
import { filterFieldsText, PaywallType } from "../../shared/utils";
import { IxopayFormProps } from "../../shared/types";
import { useIxopayFormData } from "../../shared/hooks";

export interface IxopayFormInitialValues {
    card_holder?: string;
    expiry?: string;
}

const defaultValues: IxopayFormInitialValues = {
    card_holder: "",
    expiry: "",
};

const PaymentForm: FC<IxopayFormProps> = observer(({ data, paypalPlan }) => {
    const { t } = useTranslation();
    const { flowOuter, amplitudeUserId, abTestData } = useAppData();
    const { userWebSession } = useUserStore();
    const userData = userWebSession?.session?.user;
    const [checked, setChecked] = useState(flowOuter || false);

    const userEmail = userData?.email || "";
    const paymentJsRef = useRef<any>(null);
    const formSpyRef = useRef<any>(null);
    const hasFormData = Boolean(data);
    const { showPaymentPlans, plan } = data || {};
    const priceValue = PAYMENT_PLAN_TYPE === PaymentPlanType.SUBSCRIPTION_WITH_TRIAL ? plan?.trialPrice : plan?.price;
    const isUsUser = userData?.countryCode === "US";
    const isUSAflowOuter = flowOuter && isUsUser;
    const isVATUser = VATCountries.some((country) => country.code === userData?.countryCode);

    const abTestPaymentScreenOptionsLegal = userWebSession?.session?.abTestData?.abTestPaymentScreenOptionsLegal || abTestData?.abTestPaymentScreenOptionsLegal;
    const abTestPaymentScreenOptionsNonLegal =
        userWebSession?.session?.abTestData?.abTestPaymentScreenOptionsNonLegal || abTestData?.abTestPaymentScreenOptionsNonLegal;

    const paymentScreenOptionsLegal = JSON.parse(abTestPaymentScreenOptionsLegal);
    const paymentScreenOptionsNonLegal = JSON.parse(abTestPaymentScreenOptionsNonLegal);

    const { handleSubmit, spinnerLoading, loading } = useIxopayFormData({
        formSpyRef,
        paymentJsRef,
        hasFormData,
        data,
        userEmail,
        paywall: PaywallType.default,
    });

    const isActivePaymentMethod = (provider: "ixopay" | "paypal") => {
        const providerActivationCode = provider === "paypal" ? 1 : 2;
        return (
            (!flowOuter &&
                (paymentScreenOptionsLegal[userData?.countryCode] === providerActivationCode || paymentScreenOptionsLegal[userData?.countryCode] === 3)) ||
            (flowOuter &&
                (paymentScreenOptionsNonLegal[userData?.countryCode] === providerActivationCode || paymentScreenOptionsNonLegal[userData?.countryCode] === 3))
        );
    };
    const isShowPaypal = isActivePaymentMethod("paypal");
    // TODO remove first half of condition after bank approvement
    const showIxopayForm = (!isProductionHost() && !flowOuter) || isActivePaymentMethod("ixopay");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    if (!hasFormData) return null;

    return (
        <FormContainer
            sx={{
                position: "relative",
                padding: flowOuter && isShowPaypal && !showIxopayForm ? "1.5rem 0" : undefined,
            }}
        >
            <Stack
                mb={0}
                sx={
                    flowOuter && isShowPaypal && !showIxopayForm
                        ? {
                              padding: "0 1.5rem",
                              marginBottom: "3rem",
                              maxHeight: flowOuter ? "322px" : "unset",
                              overflowY: flowOuter ? "auto" : "unset",
                              "-webkit-overflow-scrolling": "touch",
                              "&::-webkit-scrollbar": {
                                  width: "8px",
                                  transform: "translateX(30px)",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#888",
                                  borderRadius: "5px",
                              },
                              "&::-webkit-scrollbar-track": {
                                  backgroundColor: "transparent",
                                  borderRadius: "5px",
                                  height: "80px",
                              },
                          }
                        : {}
                }
            >
                <Typography variant="h3" mb="1.5rem" align="center" width="100%">
                    {t("Trial subscription")}
                </Typography>
                <Stack direction="row" justifyContent="space-between" mb="1rem">
                    <Typography variant="body1CaptionBold" color={themeColors.brandColors.brandGreen}>
                        {t("24h Trial")}
                    </Typography>
                    <Typography variant="body1CaptionBold" color={themeColors.text.tertiary}>
                        {!isNaN(priceValue) && formatterCurrencyUS.format(priceValue)}
                    </Typography>
                </Stack>
                <Divider sx={{ borderColor: themeColors.brandColors.brandLightgrey, mb: "1rem" }} />
                <Stack direction="row" justifyContent="space-between" mb="1rem">
                    <Typography variant="body1CaptionBold">{t("Total price")}:</Typography>
                    <Typography variant="body1CaptionBold" color={themeColors.text.tertiary}>
                        {!isNaN(priceValue) && formatterCurrencyUS.format(priceValue)}
                    </Typography>
                </Stack>
                {isShowPaypal && (
                    <>
                        <Box
                            sx={
                                !flowOuter && !checked
                                    ? {
                                          width: "100%",
                                          position: "relative",
                                          height: "50px",
                                          "&:before": {
                                              content: '""',
                                              position: "absolute",
                                              inset: 0,
                                              zIndex: 7,
                                              background: themeColors.background.whiteOpacity7,
                                              borderRadius: ".25rem",
                                          },
                                      }
                                    : {
                                          width: "100%",
                                          height: isUSAflowOuter ? "0" : "50px",
                                      }
                            }
                        >
                            <PaypalButton userWebSession={userWebSession} plan={paypalPlan || {}} />
                        </Box>
                        <Box
                            sx={{
                                position: "relative",
                                zIndex: 2,
                            }}
                        >
                            {isShowPaypal && showIxopayForm && (
                                <Divider
                                    variant="fullWidth"
                                    sx={{
                                        margin: "1.5rem auto",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        "&.MuiDivider-root::before": {
                                            borderTop: "1px solid #383838",
                                        },
                                        "&.MuiDivider-root::after": {
                                            borderTop: "1px solid #383838",
                                        },
                                    }}
                                >
                                    {t("or pay by a card")}
                                </Divider>
                            )}
                        </Box>
                    </>
                )}
                {showIxopayForm && (
                    <Stack direction={"column"} alignItems="center" flex={1} spacing="24px">
                        {amplitudeUserId ? (
                            <Form
                                id="ixopay-payment-form"
                                className="ixopay-payment-form"
                                onSubmit={handleSubmit as SubmitHandler<Record<string, any>>}
                                defaultValues={defaultValues}
                                resolver={zodResolver(ixopayFormSchema)}
                            >
                                {({ formState, watch, setValue }: UseFormReturn) => {
                                    const { errors: formErrors } = formState;

                                    useEffect(() => {
                                        const subscription = watch((values) => {
                                            filterFieldsText(values, setValue);
                                        });
                                        return () => subscription.unsubscribe();
                                    }, [watch]);

                                    return (
                                        <Stack direction="column" flex={1} spacing="15px">
                                            <IxopayFormSpy ref={formSpyRef} />

                                            <Box className="box--empty" />
                                            {showPaymentPlans && (
                                                <Typography variant="h3" alignSelf="center" align="center" maxWidth="260px">
                                                    {plan?.name}
                                                </Typography>
                                            )}
                                            <Stack direction="column" spacing="8px">
                                                <Stack direction="column" sx={{ width: "100%" }}>
                                                    <Stack direction="column" spacing="3px">
                                                        <Box id="number" sx={{ height: "67px", width: "100%" }} />
                                                    </Stack>
                                                    <FormControl
                                                        color="error"
                                                        error={Boolean(formErrors.number)}
                                                        errorMessage={(formErrors.number?.message || "") as string}
                                                    />
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    flex={1}
                                                    spacing="1rem"
                                                    alignItems="flex-start"
                                                    sx={{ height: "69px", alignItems: "stretch", gap: 0 }}
                                                >
                                                    <Box flex={1} gap={0}>
                                                        <StyledTextInput
                                                            className="ixopay-form-textfield"
                                                            name="expiry"
                                                            type="tel"
                                                            variant={"outlined"}
                                                            labelPlacement="title"
                                                            placeholder={t("MM/YY")}
                                                            disabled={loading}
                                                            showErrorText={true}
                                                            InputProps={{ inputMode: "numeric" }}
                                                            required
                                                        />
                                                    </Box>
                                                    <Stack direction="column" flex={1} sx={{ width: "100%" }}>
                                                        <Stack direction="column" spacing="4px" mt="4px">
                                                            <Box id="cvv" sx={{ height: "67px", width: "100%" }} />
                                                        </Stack>
                                                        <FormControl
                                                            color="error"
                                                            error={Boolean(formErrors.cvv)}
                                                            errorMessage={(formErrors.cvv?.message || "") as string}
                                                        />
                                                    </Stack>
                                                </Stack>
                                                <StyledTextInput
                                                    className="ixopay-form-textfield"
                                                    name="card_holder"
                                                    type="text"
                                                    variant={"outlined"}
                                                    labelPlacement="title"
                                                    placeholder={t("Cardholder name")}
                                                    disabled={loading}
                                                    showErrorText={true}
                                                    required
                                                />
                                            </Stack>

                                            <Button
                                                className="button--primary btn ok anim"
                                                type="submit"
                                                variant="themePrimary"
                                                disabled={loading || !checked} // !formState.isDirty
                                                fullWidth
                                                disableRipple
                                                disableElevation
                                                sx={{ marginTop: "20px !important", width: "100%", px: "unset" }}
                                            >
                                                {spinnerLoading ? <CircularProgress sx={{ color: themeColors.text.white }} /> : t("Continue")}
                                            </Button>
                                        </Stack>
                                    );
                                }}
                            </Form>
                        ) : null}

                        <Box className="ixopay-3ds-flow" sx={{ marginTop: "0px !important" }} />
                    </Stack>
                )}

                <DisclaimersFormBottomBlock
                    onChange={handleChange}
                    checked={checked}
                    plan={plan}
                    ixopayFormShown={showIxopayForm}
                    isVATUser={isVATUser}
                    isUSAUser={isUsUser}
                />
            </Stack>
        </FormContainer>
    );
});

export default PaymentForm;
