import { Button, Stack, TextareaAutosize, Typography, CircularProgress, TextField } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useMemo } from "react";
import { useAppData } from "providers/RootStoreProvider";
import { Controller, SubmitHandler, UseFormReturn } from "react-hook-form";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import Form from "components/form-components/Form";
import { useBoolean } from "hooks/useBoolean";
import { ActionItem, Modal } from "components/DevToolsWidget/components/shared";
import { isValidJsonObject } from "helpers/isValidJsonObject";

const FirebaseConfigForm = () => {
    const { abTestData, setAbTestData } = useAppData();
    const [isOpen, setIsOpen] = useBoolean(false);

    const abTestDataStringified = JSON.stringify(abTestData, null, 2);

    const defaultValues = useMemo(() => {
        return { fbconfig: abTestDataStringified };
    }, [abTestDataStringified]);

    const handleSubmit = async (methods: UseFormReturn, values: any): Promise<void> => {
        const isValidJson = isValidJsonObject(values.fbconfig);

        if (!isValidJson) {
            methods.setError("fbconfig", { message: "Config is not valid JSON object" });
            return;
        }

        await setAbTestData(JSON.parse(values.fbconfig));

        setIsOpen.off();
    };

    return (
        <ActionItem
            tooltipTitle={"Firebase Config"}
            FabProps={{ disabled: !abTestData }}
            icon={
                <>
                    <Stack justifyContent="center" alignItems="center" onClick={setIsOpen.on} sx={{ width: "100%", height: "100%" }}>
                        <AltRouteIcon sx={(theme) => ({ color: theme.palette.brandColors.brandGreen })} />
                    </Stack>
                    <Modal open={isOpen} onClose={setIsOpen.off}>
                        <Stack direction={"column"} gap="12px">
                            <Typography align="center" variant="h2">
                                Edit Firebase Config
                            </Typography>

                            <Form className="devtools-edit-form" onSubmit={handleSubmit as SubmitHandler<Record<string, any>>} defaultValues={defaultValues}>
                                {({ control, formState }) => (
                                    <Stack direction="column" flex={1} spacing="16px">
                                        <Controller
                                            name="fbconfig"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    variant="outlined"
                                                    disabled={formState.isSubmitting}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    InputProps={{
                                                        inputComponent: TextareaAutosize,
                                                        inputProps: {
                                                            onKeyDown: (e) => {
                                                                e.stopPropagation();
                                                            },
                                                        },
                                                    }}
                                                    required
                                                />
                                            )}
                                        />
                                        <Button
                                            type="submit"
                                            variant="themePrimarySmall"
                                            color="primary"
                                            disabled={!formState.isValid || formState.isSubmitting}
                                            disableRipple
                                            disableElevation
                                            sx={{ gap: "6px" }}
                                        >
                                            {formState.isSubmitting ? <CircularProgress size={24} /> : <SaveOutlinedIcon />}
                                            Save
                                        </Button>
                                    </Stack>
                                )}
                            </Form>
                        </Stack>
                    </Modal>
                </>
            }
        />
    );
};

export default FirebaseConfigForm;
