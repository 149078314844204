import { supabase } from "services/supabase";
import { UserWebSession } from "stores/user.store";

export const createWebSession = async (email: string, password: string) => {
    try {
        const { data, error } = await supabase.auth.signUp({
            email,
            password,
        });
        if (error) {
            throw new Error(error.message);
        }

        return data.user?.user_metadata;
    } catch (e) {
        console.error("error:", e);
        return null;
    }
};

export const getUserMetadata = async (): Promise<UserWebSession | null> => {
    try {
        const { data, error } = await supabase.auth.getUser();

        if (error) {
            throw new Error(error.message);
        }

        return data?.user?.user_metadata as any;
    } catch (e) {
        console.error("error:", e);
        return null;
    }
};

export const updateWebSession = async (body: UserWebSession) => {
    try {
        const { data, error } = await supabase.auth.updateUser({
            data: body,
        });

        if (error) {
            throw new Error(error.message);
        }
        // return data.user.user_metadata as any
        return data?.user;
    } catch (e) {
        console.error("error:", e);
        return null;
    }
};
