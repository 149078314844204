// TODO finish paypal payment handling, temporary disabled
import { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { DISPATCH_ACTION, FUNDING, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { CreateSubscriptionActions, OnApproveActions, OnApproveData } from "@paypal/paypal-js";

import { useAppData } from "providers/RootStoreProvider";
import { createPaypalSubscription } from "http-client/payment.client";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";
import { UserWebSession } from "stores/user.store";

interface Props {
    userWebSession: UserWebSession | null;
    type?: string;
    plan: Record<string, any> | null;
}

const ButtonWrapper = observer(({ userWebSession, type, plan }: Props) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();
    const navigate = useNavigate();
    const { amplitudeUserId } = useAppData();

    const handleAmplitudeClick = () => {
        handleAmpliLoaded(() =>
            ampli.subscriptionSubscribeClicked({
                type: "paywall / payment provider: paypal",
                product_id: plan?.planId || "",
                plan: plan?.name || "",
            })
        );
    };

    useEffect(() => {
        dispatch({
            type: DISPATCH_ACTION.RESET_OPTIONS,
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type, plan?.planId]);

    const createSubscription = (data: Record<string, unknown>, actions: CreateSubscriptionActions) => {
        return actions.subscription.create({
            plan_id: plan?.planId,
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
        const { subscriptionID } = data;
        const paypalData = await createPaypalSubscription({
            userId: userWebSession?.session?.user?.id,
            planId: plan?.id,
            paypalSubId: subscriptionID as string,
        });
        if (!paypalData) {
            navigate({
                pathname: "/decline",
                search: createSearchParams({
                    provider: "paypal",
                    usid: amplitudeUserId,
                }).toString(),
            });
        }
        navigate({
            pathname: "/success",
            search: createSearchParams({
                provider: "paypal",
                usid: amplitudeUserId,
            }).toString(),
        });
    };

    const onError = () => {
        navigate({
            pathname: "/decline",
            search: createSearchParams({
                provider: "paypal",
                usid: amplitudeUserId,
            }).toString(),
        });
    };

    return (
        <PayPalButtons
            fundingSource={FUNDING.PAYPAL}
            onClick={handleAmplitudeClick}
            createSubscription={createSubscription}
            onApprove={onApprove}
            onError={onError}
            style={{ height: 50 }}
        />
    );
});

export const PaypalButton = ({ userWebSession, plan }: Props) => {
    return (
        <div style={{ minHeight: "55px", position: "relative", zIndex: 5 }}>
            <ButtonWrapper userWebSession={userWebSession} plan={plan} />
        </div>
    );
};
