import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Stack, styled, Typography } from "@mui/material";

import { BoldToNormalText, FormWithDiscountAndDisclaimer, IconTextItem } from "components/Payment/shared/components";
import { IxopayFormProps, PaymentButtonTitleVariants } from "../../shared/types";
import { useAppData } from "providers/RootStoreProvider";
import { themeColors } from "constants/colors.const";
import { LoadingButton } from "components/LoadingButton";
import { ReactComponent as BackArrowIcon } from "assets/icons/back-arrow.svg";

import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";
import { benefitsV3 } from "components/Payment/shared/constants";
import { useFakeSearch } from "hooks/useFakeSearch";
import { useBoolean } from "hooks/useBoolean";
import FakeSearchMap from "components/Map/FakeSearchMap";
import { formatNumber } from "libphonenumber-js";
import { SearchCard } from "components/FakeSearchStages/SearchCard";
import { PaywallVariants } from "enums/PaywallVariants";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { PlanVariantsList } from "./components";
import { useTranslation } from "hooks/useTranslation";
import { getIpInfo } from "http-client/ip-info";
import { saveToSessionStorage } from "services/storage";
import { CountdownTimer } from "./components/CountdownTimer";

const StyledPaywallContainer = styled(Box)(({ theme }) => ({
    margin: "8.5rem auto 0",
    width: "866px",
    background: theme.palette.background.grey,
    borderRadius: "1.5rem",
    padding: "60px 40px",
    [theme.breakpoints.down("sm")]: {
        width: "100vw",
        margin: "65px 0 -11vh -20px",
        padding: 0,
        borderRadius: "1.5rem 1.5rem 0 0",
        "&:dir(rtl)": {
            margin: "65px -20px -11vh 0",
        },
    },
}));

const defaultPlanExtraData = {
    title: "24h Trial",
    oldPrice: 9.99,
};

const placeholderLocation = {
    lat: 50.397061,
    lng: -19.746916,
};

interface Props extends IxopayFormProps {
    plans: any[] | undefined;
    withTimer?: boolean;
}

const PaymentForm: FC<Props> = observer(({ data, plans, withTimer }) => {
    const { t, detect } = useTranslation();
    const { isMobile } = useDeviceDetect();
    const { isPaymentMapFormShown, setIsPaymentMapFormShown, searchPhoneNumber } = useAppData();
    const { searchLocation, staticSearchCards } = useFakeSearch({ withMap: true, disableRedirect: true, disableClickEventSend: true });
    const [isMapLoaded, setIsMapLoaded] = useBoolean(false);
    const defaultPlan = plans?.find((plan) => plan.trialPriceVat === 4.99 && plan.priceVat === 49.99);
    const [chosenPlan, setChosenPlan] = useState<Record<string, any> | undefined>(defaultPlan);
    const [planExtraData, setPlanExtraData] = useState(defaultPlanExtraData);
    const [userLocation, setUserLocation] = useState<Record<string, any>>(searchLocation ?? placeholderLocation);

    const isLongPhoneNumber = searchPhoneNumber.length >= 12;
    const hasFormData = Boolean(data);

    const setPlanAndStoragePlan = (plan) => {
        setChosenPlan(plan);
        saveToSessionStorage("selected-plan", JSON.stringify(plan));
    };

    useEffect(() => {
        if (searchLocation) return;

        (async () => {
            const ipInfo = await getIpInfo(true);
            if (ipInfo) {
                const location = ipInfo?.loc.split(",");
                setUserLocation({
                    lat: +location[0],
                    lng: +location[1],
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (!defaultPlan) return;
        setPlanAndStoragePlan(defaultPlan);
    }, [defaultPlan]);

    const handleMapLoaded = () => {
        setTimeout(() => {
            setIsMapLoaded.on();
        }, 400);
    };

    const handleContinueClick = () => {
        handleAmpliLoaded(() =>
            ampli.subscriptionContinueClicked({
                type: withTimer ? PaywallVariants.MapV4 : PaywallVariants.MapV3,
                plan: chosenPlan?.name || "",
            })
        );
        setIsPaymentMapFormShown(true);
        window.scrollTo(0, 0);
    };

    const handlePlanChange = ({ priceVat, trialPriceVat, oldPrice, title }) => {
        const currentPlan = plans?.find((plan) => plan?.priceVat === priceVat && plan.trialPriceVat === trialPriceVat);
        setPlanAndStoragePlan(currentPlan);
        setPlanExtraData({ oldPrice, title });
    };

    if (!hasFormData) return null;

    return (
        <StyledPaywallContainer
            sx={{
                zIndex: 1,
                position: "relative",
            }}
        >
            {isPaymentMapFormShown && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "16px",
                        left: "16px",
                        cursor: "pointer",
                        "& path": {
                            fill: "#fff",
                            fillOpacity: 0.7,
                        },
                    }}
                >
                    <BackArrowIcon onClick={() => setIsPaymentMapFormShown(false)} />
                </Box>
            )}
            <Stack
                sx={{
                    flexDirection: { xs: "column", lmd: "row" },
                    padding: { xs: "0 0 39px", lmd: 0 },
                }}
            >
                {(!isMobile || !isPaymentMapFormShown) && (
                    <Stack
                        sx={(theme) => ({
                            width: {
                                xs: "100%",
                                sm: "48%",
                            },
                            flexShrink: 0,
                            gap: "10px",
                            position: "relative",
                            padding: "30px 32px",
                            borderRadius: { xs: "16px 16px 0 0", lmd: "16px 0 16px 0" },
                            border: { xs: "none", lmd: `1px solid ${theme.palette.background.brightGreen}` },
                            borderTop: { xs: `1px solid ${theme.palette.background.brightGreen}`, lmd: `1px solid ${theme.palette.background.brightGreen}` },
                            margin: { xs: "0 auto 16px", lmd: "0" },
                            height: { xs: "256px", lmd: "597px" },
                            justifyContent: { xs: "center", lmd: "unset" },
                        })}
                    >
                        <Stack sx={{ justifyContent: "space-between", height: { xs: "unset", lmd: "100%" }, overflow: "hidden" }}>
                            {searchLocation && (
                                <Stack
                                    direction="row"
                                    gap="12px"
                                    sx={(theme) => ({
                                        padding: "13px 16px",
                                        borderRadius: "16px",
                                        border: `2px solid ${theme.palette.text.quaternary}`,
                                        background: "rgba(114, 120, 154, 0.20)",
                                        backdropFilter: "blur(5px)",
                                    })}
                                >
                                    <img
                                        src={"/icons/checked-bluegrey.svg"}
                                        alt="Located"
                                        style={{
                                            width: "44px",
                                            height: "auto",
                                            alignSelf: "flex-start",
                                        }}
                                    />
                                    <Box>
                                        <Typography
                                            variant="title1Bold"
                                            color={themeColors.text.white}
                                            whiteSpace="nowrap"
                                            dir="ltr"
                                            sx={{
                                                fontSize: {
                                                    xs: isLongPhoneNumber ? "18px" : "20px",
                                                    sm: isLongPhoneNumber ? "20px" : "22px",
                                                },
                                            }}
                                        >
                                            {formatNumber(searchPhoneNumber, "INTERNATIONAL")}
                                        </Typography>
                                        <Typography variant="smallRegular" sx={{ color: "text.secondary", fontSize: "12px", display: "block" }}>
                                            {t("Located")}!
                                        </Typography>
                                    </Box>
                                </Stack>
                            )}

                            {!isMobile && searchLocation && (
                                <Stack direction="column" gap="10px" height="255px">
                                    {staticSearchCards.map((card, index) => (
                                        <SearchCard
                                            label={t(card.label)}
                                            value={card.value}
                                            blurred={card.blurred}
                                            defined={card.defined}
                                            key={card.label + index}
                                            accentColor="#72789A"
                                        />
                                    ))}
                                </Stack>
                            )}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    borderRadius: { xs: "16px 16px 0 0", lmd: "16px 0 16px 0" },
                                    width: "100%",
                                    height: "100%",
                                    overflow: "hidden",
                                }}
                            >
                                <FakeSearchMap
                                    lat={searchLocation?.lat || userLocation.lat || 50.397061}
                                    lng={searchLocation?.lng || userLocation.lng || -19.746916}
                                    zoom={searchLocation ? 14 : userLocation ? 12 : 2}
                                    onLoad={handleMapLoaded}
                                    mapContainerStyle={{
                                        width: "115%",
                                        height: "115%",
                                        zIndex: -1,
                                        opacity: isMapLoaded ? 1 : 0,
                                        transition: ".6s",
                                        transform: detect.isArabicLanguage ? "translate(7%, -7%)" : "translate(-7%, -7%)",
                                    }}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                )}

                <Stack
                    flex={1}
                    sx={{
                        padding: { xs: "0 0 2rem", lmd: "0 34px 0 40px" },
                        display: "flex",
                    }}
                >
                    <Box>
                        {isPaymentMapFormShown && isMobile && (
                            <>
                                <Typography
                                    variant="dmSansH4Medium"
                                    sx={{
                                        mb: "24px",
                                        mt: "32px",
                                        textAlign: "center",
                                        color: "text.secondary",
                                        display: "block",
                                        fontSize: "28px",
                                    }}
                                >
                                    {t("Choose your plan")}
                                </Typography>
                                {withTimer && <CountdownTimer initialSeconds={300} sx={{ maxWidth: "calc(100% - 32px)", margin: "-16px auto 16px" }} />}
                            </>
                        )}
                        {isPaymentMapFormShown ? (
                            <Box sx={{ padding: { xs: "16px", lmd: 0 } }}>
                                {isMobile && <PlanVariantsList chosenPlan={chosenPlan} onChange={handlePlanChange} />}
                                <Box sx={{ mt: { xs: "16px", lmd: "-3rem" } }}>
                                    <FormWithDiscountAndDisclaimer
                                        data={{ ...data, plan: chosenPlan }}
                                        planExtraData={planExtraData}
                                        submitButtonTitle={PaymentButtonTitleVariants.CONTINUE}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ padding: { xs: "0 16px 16px", lmd: 0 } }}>
                                {!isPaymentMapFormShown &&
                                    (isMobile ? (
                                        <Typography variant="dmSansH4Medium" color="text.secondary" textAlign="center" mb="16px" display="block">
                                            {t("Unlimited Access")}
                                        </Typography>
                                    ) : (
                                        <>
                                            <Typography variant="dmSansH4Medium" color="text.secondary" textAlign="center" mb="32px" display="block">
                                                {t("Choose your plan")}
                                            </Typography>
                                            {withTimer && <CountdownTimer initialSeconds={300} sx={{ margin: "-24px 0 32px 0" }} />}
                                        </>
                                    ))}

                                {!isMobile && <PlanVariantsList chosenPlan={chosenPlan} onChange={handlePlanChange} />}
                                {benefitsV3.map(({ text, icon }, index) => {
                                    const [title, subtitle] = t(text).split(":");
                                    const isLastItem = index === benefitsV3.length - 1;

                                    return (
                                        <IconTextItem
                                            key={`benefit-${text}`}
                                            icon={icon}
                                            sx={(theme) => ({
                                                mb: "16px",
                                                "& svg": {
                                                    flexShrink: 0,
                                                    "& path": {
                                                        fill: theme.palette.text.quaternary,
                                                    },
                                                },
                                            })}
                                            text={
                                                <BoldToNormalText
                                                    title={isLastItem ? title : `${title}:`}
                                                    text={subtitle}
                                                    sx={{
                                                        "& span": {
                                                            color: "text.secondary",
                                                            "&:last-of-type": {
                                                                ml: "4px",
                                                            },
                                                            display: "inline",
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                    );
                                })}
                                <LoadingButton
                                    variant="themePrimarySmall"
                                    fullWidth
                                    disableRipple
                                    disableElevation
                                    onClick={handleContinueClick}
                                    sx={{
                                        width: "100%",
                                        px: "unset",
                                        mb: "1rem",
                                        mt: { xs: 0, sm: "2.3rem" },
                                        maxWidth: "100%",
                                        textTransform: "uppercase",
                                        background: themeColors.background.brightGreen,
                                    }}
                                >
                                    <Typography variant="bodyInterExtraBold">{t("Continue")}</Typography>
                                </LoadingButton>
                            </Box>
                        )}
                    </Box>
                </Stack>
            </Stack>
        </StyledPaywallContainer>
    );
});

export default PaymentForm;
