import { useEffect } from "react";
import { ExtraOffersType } from "../ExtraOffers.types";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";

export const useOfferEvents = ({ offerName, open }: { offerName: ExtraOffersType; open: boolean }) => {
    useEffect(() => {
        if (open) {
            handleAmpliLoaded(() => ampli.specialOfferShown({ offer_name: offerName }));
        }
    }, [open]);

    const handleAccept = (callback?: () => void) => {
        handleAmpliLoaded(() => ampli.specialOfferAccepted({ offer_name: offerName }));
        callback?.();
    };

    const handleDecline = (callback?: () => void) => {
        handleAmpliLoaded(() => ampli.specialOfferRejected({ offer_name: offerName }));
        callback?.();
    };

    return {
        handleAccept,
        handleDecline,
    };
};
