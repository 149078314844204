import { Stack, CircularProgress } from "@mui/material";
import { themeColors } from "constants/colors.const";

export const PaymentFormLoader = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: "100%",
                flexGrow: 1,
            }}
        >
            <CircularProgress size="2rem" sx={{ color: themeColors.brandColors.brandGreen, margin: "30vh auto" }} />
        </Stack>
    );
};
