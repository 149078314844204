import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import { useAppData, useLocationsStore } from "providers/RootStoreProvider";
import { DesktopMenu, MobileMenu } from "./Menu";
import { AccountFeatureProvider } from "../context";
import { AppRoutes } from "enums/routes.enum";
import { LandingMode } from "constants/landing-variants.const";
import { ExtraOffers } from "./ExtraOfferModals";
import { useBoolean } from "hooks/useBoolean";

const AccountLayout = observer(() => {
    const { pathname } = useLocation();
    const { flowOuter } = useAppData();
    const { fetchAllLocations } = useLocationsStore();
    const [showExtraOffer, setShowExtraOffer] = useBoolean();
    const featureKey = pathname === AppRoutes.LOST_PHONE ? LandingMode.LOST_MY_PHONE_MODE : LandingMode.LOCATE_NUMBER_MODE;

    useEffect(() => {
        fetchAllLocations();
        setTimeout(() => {
            if (flowOuter) {
                setShowExtraOffer.on();
            }
        }, 1000);
    }, []);

    return (
        <AccountFeatureProvider featureKey={featureKey}>
            <Box minHeight="100svh" position="relative" display="flex" flexDirection="column">
                <DesktopMenu />
                {showExtraOffer && <ExtraOffers />}
                <Outlet />
                <MobileMenu />
            </Box>
        </AccountFeatureProvider>
    );
});

export default AccountLayout;
