import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { BurgerMenu, LanguagePicker, WidthContainer } from "components/shared";
import { themeColors } from "constants/colors.const";
import { AppRoutes } from "enums/routes.enum";
import { useHeaderLogic } from "hooks/useHeaderLogic";
import { useRef } from "react";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { useLogout } from "hooks/useLogout";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { NavMenuNonLegal } from "./NavMenuNonLegal";
import { NonLegalMenuContent } from "./NonLegalMenuContent";
import { ReactComponent as CloseIcon } from "assets/icons/close-lightgrey.svg";
import { ReactComponent as LogoWhiteIcon } from "assets/icons/logo-white.svg";

const StyledHeaderNonLegal = styled("header")(() => ({
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    background: themeColors.text.fullBlack,
    "& *": {
        flexShrink: 0,
    },
}));

export const HeaderNonLegal = () => {
    const navigate = useNavigate();
    const logout = useLogout();
    const { isTablet } = useDeviceDetect();
    const { t, i18n } = useTranslation();
    const { user } = useUserStore();
    const { flowOuter } = useAppData();

    const isLoggedIn = !!user?.email;
    const isSmallerLoginText = i18n.language === "de" || i18n.language === "fr";

    const languagePickerRef = useRef<HTMLDivElement | null>(null);
    const sidebarRef = useRef<HTMLDivElement | null>(null);
    const sidebarTogglerRef = useRef<HTMLButtonElement | null>(null);

    const {
        handleCloseButtonClick,
        toggleSidebar,
        handleLogoClick,
        sidebar,
        isCloseButtonShown,
        isMenuTogglerShown,
        renderLoginButton,
        isHomePage,
        isLostPhonePage,
    } = useHeaderLogic({
        sidebarRef,
        sidebarTogglerRef,
        languagePickerRef,
    });

    const handleLoginClick = () => {
        if (user) {
            logout();
        } else {
            navigate(AppRoutes.LOG_IN);
        }
    };

    return (
        <>
            <StyledHeaderNonLegal style={{ zIndex: 10, padding: isTablet ? "10px 0" : "10px 0" }}>
                <WidthContainer>
                    <Stack direction="row" justifyContent="space-between">
                        <Link
                            to={isLoggedIn ? AppRoutes.ACCOUNT : AppRoutes.HOME}
                            onClick={handleLogoClick}
                            style={{
                                padding: "2px 10px",
                                borderRadius: "16px",
                                boxSizing: "border-box",
                                height: "44px",
                                marginLeft: "-10px",
                            }}
                        >
                            <LogoWhiteIcon />
                        </Link>
                        {!isTablet && (isHomePage || isLostPhonePage) && <NavMenuNonLegal />}
                        <Stack gap="12px" direction="row" justifyContent="flex-end" ref={languagePickerRef}>
                            <Box sx={{ position: "relative" }}>{flowOuter && !isTablet && <LanguagePicker />}</Box>
                            {renderLoginButton &&
                                (isTablet ? (
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        gap={isSmallerLoginText ? "3px" : "6px"}
                                        onClick={() => navigate(AppRoutes.LOG_IN)}
                                        sx={{ maxWidth: { xs: "75px", sm: "unset" } }}
                                    >
                                        {sidebar.isOpen && (
                                            <Typography
                                                variant="body2Regular"
                                                textTransform="capitalize"
                                                color={themeColors.text.grey}
                                                sx={{ maxWidth: { xs: "4rem", sm: "unset" } }}
                                                fontSize={isSmallerLoginText ? "13px" : undefined}
                                                lineHeight={1.2}
                                                textAlign="right"
                                            >
                                                {t("Log in").toLowerCase()}
                                            </Typography>
                                        )}
                                        <img src="/icons/userIcon-grey.svg" alt="user icon" />
                                    </Stack>
                                ) : (
                                    <Button variant="outlined" sx={{ height: "44px", textTransform: "uppercase" }} onClick={handleLoginClick}>
                                        <Stack direction="row" alignItems="center" gap="8px">
                                            {user ? t("Log out") : t("Log in")}
                                            <img src="/icons/userIcon-lightgrey.svg" alt="user icon" />
                                        </Stack>
                                    </Button>
                                ))}
                            {isMenuTogglerShown && (
                                <BurgerMenu open={sidebar.isOpen} onClose={toggleSidebar} sx={{ "& .MuiPaper-root": { paddingTop: "80px" } }}>
                                    <NonLegalMenuContent onClose={toggleSidebar} />
                                </BurgerMenu>
                            )}
                            {isCloseButtonShown && (
                                <Button
                                    style={{
                                        padding: 0,
                                        width: "44px",
                                        height: "44px",
                                        borderRadius: "10px",
                                        whiteSpace: "nowrap",
                                        minWidth: "44px",
                                        border: `1px solid ${themeColors.brandColors.brandGreen}`,
                                    }}
                                    onClick={handleCloseButtonClick}
                                >
                                    <CloseIcon />
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </WidthContainer>
            </StyledHeaderNonLegal>
        </>
    );
};
