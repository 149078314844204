import { Box, BoxProps, Typography } from "@mui/material";
import { FC } from "react";
interface Props extends BoxProps {
    title: string;
    text: string;
}

export const BoldToNormalText: FC<Props> = ({ title, text, sx }) => (
    <Box sx={sx}>
        <Typography
            variant="smallBold"
            sx={(theme) => ({
                display: { xs: "block", sm: "inline" },
                color: theme.palette.text.secondary,
            })}
        >
            {title}{" "}
        </Typography>
        <Typography
            variant="smallRegular"
            sx={(theme) => ({
                display: { xs: "block", sm: "inline" },
                color: theme.palette.text.disabled,
            })}
        >
            {text}
        </Typography>
    </Box>
);
