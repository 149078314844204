import { Box, Stack, styled } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { Fragment } from "react";

const StyledIconWrap = styled(Stack)(() => ({
    width: "33.3%",
    height: "44px",
    padding: "0 1.5rem",
    "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
}));

export const PaymentIconsBlock = ({ iconsList }: { iconsList?: React.ElementType[] }) => (
    <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            {iconsList?.map((Icon, index) => (
                <Fragment key={index}>
                    <StyledIconWrap alignItems="center" justifyContent="center">
                        <Icon />
                    </StyledIconWrap>
                    {index !== iconsList.length - 1 && <div style={{ height: "1.5rem", width: "1px", background: themeColors.background.borderLightGrey }} />}
                </Fragment>
            ))}
        </Stack>
    </Box>
);
