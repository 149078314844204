export const isValidJsonObject = (text?: string | null): boolean => {
    if (!text) return false;

    try {
        const parsed = JSON.parse(text);
        if (parsed && typeof parsed === "object") {
            return true;
        }
    } catch {
        return false;
    }

    return false;
};
