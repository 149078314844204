import { createTheme, experimental_extendTheme as extendTheme } from "@mui/material/styles";
import { themeFonts } from "./themeFonts.const";
import { themeColors } from "constants/colors.const";
import { Theme } from "@mui/material";

export const baseTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lmd: 1024,
            lg: 1280,
            xl: 1440,
        },
    },
    palette: {
        text: {
            primary: themeColors.text.black,
            secondary: themeColors.text.white,
            tertiary: themeColors.text.tertiary,
            reviews: themeColors.text.reviews,
            quaternary: themeColors.text.quaternary,
            disabled: themeColors.text.grey,
            black: themeColors.text.fullBlack,
            error: themeColors.state.error,
        },
        background: {
            default: themeColors.brandColors.brandWhite,
            primary: themeColors.brandColors.brandBlack,
            secondary: themeColors.text.fullBlack,
            tertiary: themeColors.text.fullBlack,
            quaternary: themeColors.background.white,
            whiteOpacity4: themeColors.background.whiteOpacity4,
            grey: themeColors.background.grey,
            whiteOpacity2: themeColors.background.whiteOpacity2,
            primaryLight: themeColors.background.primary,
            brightGreen: themeColors.background.brightGreen,
            lightGrey: themeColors.background.lightGrey,
            lightAccent: themeColors.background.lighterGrey,
            darkGrey: themeColors.background.darkGrey,
        },
        brandColors: {
            brandGreen: themeColors.brandColors.brandGreen,
            brandBlack: themeColors.brandColors.brandBlack,
            brandWhite: themeColors.brandColors.brandWhite,
            brandLightgrey: themeColors.brandColors.brandLightgrey,
        },
    },
});

export const theme = extendTheme(baseTheme, {
    typography: {
        h1: {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "34px",
            lineHeight: 1.285,
            fontWeight: 700,
        },
        h3: {
            fontFamily: themeFonts.salara,
            fontSize: "28px",
            lineHeight: 1.285,
            fontWeight: 700,
        },
        title2Regular: {
            fontFamily: themeFonts.salara,
            fontSize: "22px",
            lineHeight: 1.333,
            fontWeight: 400,
        },
        titleBold: {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "24px",
            lineHeight: 1.333,
            fontWeight: 800,
        },
        title1Bold: {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "22px",
            lineHeight: 1.333,
            fontWeight: 700,
        },
        title3Bold: {
            fontFamily: themeFonts.inter,
            fontSize: "20px",
            lineHeight: 1.22,
            fontWeight: 700,
        },
        body1Bold: {
            fontFamily: themeFonts.geologica,
            fontSize: "20px",
            lineHeight: 1.2,
            fontWeight: 700,
        },
        body2Regular: {
            fontFamily: themeFonts.salara,
            fontSize: "16px",
            lineHeight: 1.5,
            fontWeight: 400,
        },
        body2Medium: {
            fontFamily: themeFonts.salara,
            fontSize: "16px",
            lineHeight: 1.5,
            fontWeight: 500,
        },
        body2Semibold: {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "16px",
            lineHeight: 1.5,
            fontWeight: 600,
        },
        bodyInterExtraBold: {
            fontFamily: themeFonts.inter,
            fontSize: "17px",
            lineHeight: 1.4,
            fontWeight: 800,
        },
        body1Medium: {
            fontFamily: themeFonts.geologica,
            fontSize: "22px",
            lineHeight: 1.428,
            fontWeight: 500,
        },
        body1CaptionBold: {
            fontFamily: themeFonts.salara,
            fontSize: "16px",
            lineHeight: 1.333,
            fontWeight: 700,
        },
        smallRegular: {
            fontFamily: themeFonts.salara,
            fontSize: "14px",
            lineHeight: 1.333,
            fontWeight: 400,
        },
        smallThin: {
            fontFamily: themeFonts.geologica,
            fontSize: "11px",
            lineHeight: 1.333,
            fontWeight: 300,
        },
        smallBold: {
            fontFamily: themeFonts.salara,
            fontSize: "14px",
            lineHeight: 1.333,
            fontWeight: 700,
        },
        smallInterRegular: {
            fontFamily: themeFonts.inter,
            fontSize: "14px",
            lineHeight: 1.22,
            fontWeight: 400,
        },
        smallInterMedium: {
            fontFamily: themeFonts.inter,
            fontSize: "14px",
            lineHeight: 1.22,
            fontWeight: 500,
        },
        footnoteRegular: {
            fontFamily: themeFonts.salara,
            fontSize: "11px",
            lineHeight: "16px",
            fontWeight: 400,
        },
        dmSansH3Semibold: {
            fontFamily: themeFonts.dmSans,
            fontSize: "48px",
            lineHeight: "50px",
            fontWeight: 600,
        },
        dmSansH4Medium: {
            fontFamily: themeFonts.dmSans,
            fontSize: "34px",
            lineHeight: "40px",
            fontWeight: 500,
        },
        dmSansH5Regular: {
            fontFamily: themeFonts.dmSans,
            fontSize: "23px",
            lineHeight: "32px",
            fontWeight: 400,
        },
        dmSansH4H5Semibold: {
            fontFamily: themeFonts.dmSans,
            fontSize: "28px",
            lineHeight: "32px",
            fontWeight: 600,
        },
        dmSansH4Semibold: {
            fontFamily: themeFonts.dmSans,
            fontSize: "34px",
            lineHeight: "40px",
            fontWeight: 600,
            letterSpacing: "0.25px",
        },
        dmSansH5Semibold: {
            fontFamily: themeFonts.dmSans,
            fontSize: "23px",
            lineHeight: "32px",
            fontWeight: 600,
        },
        dmSansBody1Regular: {
            fontFamily: themeFonts.dmSans,
            fontSize: "16px",
            lineHeight: "23px",
            fontWeight: 400,
        },
        dmSansBody2Regular: {
            fontFamily: themeFonts.dmSans,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
        },
        dmSansBody2Semibold: {
            fontFamily: themeFonts.dmSans,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 600,
        },
    },
    components: {
        MuiPopper: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    "&.popper--places-autocomplete": {
                        boxShadow: "var(--mui-shadows-2)",
                        zIndex: "calc(var(--mui-zIndex-appBar) - 10)",
                        maxWidth: "350px",
                        borderRadius: "12px",
                        marginTop: "2px !important",
                        "& .paper--places-autocomplete": {
                            boxShadow: "none",
                            backgroundColor: "transparent",
                        },
                        "& .MuiList-root": {
                            margin: 0,
                            padding: 0,
                            boxShadow: "none",
                            borderRadius: "12px",
                            maxHeight: "210px !important",
                            backgroundColor: "rgba(243, 244, 247, 0.95)",
                            overflow: "auto",
                        },
                        "& .MuiMenuItem-root": {
                            width: "100%",
                            maxWidth: "350px",
                            padding: "13px !important",
                            overflow: "hidden",
                            "&:hover": {
                                backgroundColor: themeColors.state.buttonHoverBg,
                                color: theme.palette.text.secondary,
                            },
                            "&:not(:last-child)": {
                                borderBottom: `0.5px solid ${theme.palette.brandColors.brandLightgrey}`,
                            },
                            ".MuiTypography-root": {
                                fontFamily: themeFonts.salara,
                                fontSize: "14px",
                                lineHeight: 1.333,
                                fontWeight: 400,
                            },
                        },
                    },
                }),
            },
        },
        MuiSvgIcon: {
            variants: [
                {
                    props: { fontSize: "large" },
                    style: {
                        fontSize: "2rem",
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    "&:disabled": {
                        background: themeColors.state.buttonDisabledBg,
                        color: themeColors.text.white,
                    },
                    "&:hover": {
                        background: themeColors.state.buttonHoverBg,
                        color: themeColors.text.white,
                    },
                },
            },

            variants: [
                {
                    props: { variant: "themePrimary" },
                    style: {
                        backgroundColor: themeColors.brandColors.brandGreen,
                        fontFamily: themeFonts.sourceCodePro,
                        color: themeColors.text.white,
                        fontWeight: 800,
                        fontSize: "24px",
                        padding: "18px 130px",
                        borderRadius: "16px",
                        height: "67px",
                    },
                },
                {
                    props: { variant: "themePrimarySmall" },
                    style: {
                        backgroundColor: themeColors.brandColors.brandGreen,
                        color: themeColors.text.white,
                        fontWeight: 800,
                        fontSize: "16px",
                        padding: "12px 40px",
                        borderRadius: "12px",
                    },
                },
                {
                    props: { variant: "xs" },
                    style: {
                        backgroundColor: themeColors.brandColors.brandGreen,
                        color: themeColors.text.white,
                        fontWeight: 700,
                        fontSize: "12px",
                        padding: ".25rem .5rem",
                        borderRadius: "6px",
                        "&.disabled-active-view:disabled": {
                            color: "#fff !important",
                        },
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        backgroundColor: "transparent",
                        color: themeColors.text.white,
                        fontWeight: 800,
                        fontFamily: themeFonts.sourceCodePro,
                        fontSize: "16px",
                        padding: "14px 40px",
                        borderRadius: "12px",
                        border: `1px solid ${themeColors.brandColors.brandGreen}`,
                        "&:hover": {
                            borderColor: themeColors.brandColors.brandGreen,
                        },
                    },
                },
            ],
        },
    },
});
