import { authToken } from "constants/authToken.const";

export const loadFromStorage = (key: string) => {
    const value = localStorage.getItem(key);
    if (value) {
        try {
            const json = JSON.parse(value);
            return json;
        } catch (e) {
            return value;
        }
    }
};

export const saveToStorage = (key: string, value: any) => {
    localStorage.setItem(key, value);
};

export const removeFromStorage = (key: string) => {
    localStorage.removeItem(key);
};

export const loadTokensFromStorage = (): {
    token?: string;
    refreshToken?: string;
} => {
    return loadFromStorage(authToken);
};

export const saveTokensToStorage = (tokens: any) => {
    const json = JSON.stringify(tokens);
    saveToStorage(authToken, json);
};

export const removeTokensFromStorage = (): void => {
    return removeFromStorage(authToken);
};

export const loadFromSessionStorage = (key: string) => {
    if (typeof window !== "undefined") {
        const value = sessionStorage.getItem(key);
        if (value) {
            try {
                const json = JSON.parse(value);
                return json;
            } catch (e) {
                return value;
            }
        }
    }
};

export const saveToSessionStorage = (key: string, value: any) => {
    if (typeof window !== "undefined") {
        sessionStorage.setItem(key, value);
    }
};

export const removeFromSessionStorage = (key: string) => {
    if (typeof window !== "undefined") {
        sessionStorage.removeItem(key);
    }
};

export const removeItemsFromStorage = (...items: string[]) => {
    items.forEach((item) => {
        localStorage.removeItem(item);
    });
};
