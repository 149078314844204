import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Stack } from "@mui/material";
import { ActionItem } from "components/DevToolsWidget/components/shared";
import { useDevTools } from "components/DevToolsWidget/context";

const HideDevTools = () => {
    const { setDevToolsVisibility } = useDevTools();

    return (
        <ActionItem
            icon={
                <Stack justifyContent="center" alignItems="center" onClick={setDevToolsVisibility.off} sx={{ width: "100%", height: "100%" }}>
                    <VisibilityOffIcon sx={(theme) => ({ color: theme.palette.brandColors.brandGreen })} />
                </Stack>
            }
            tooltipTitle="Hide"
        />
    );
};

export default HideDevTools;
