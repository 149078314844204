import { FC, ReactElement, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import TextField, { TextFieldProps } from "components/form-components/TextField";

export interface FormTextFieldProps extends TextFieldProps {
    name: string;
    onCustomChange?: (inputText?: string) => void;
    onErrorChange?: (errText?: string) => void;
}

export const IxopayFormTextField: FC<FormTextFieldProps> = ({ name, onCustomChange, onErrorChange, ...props }): ReactElement => {
    const { register, formState } = useFormContext();
    const [error, setError] = useState<string | undefined>();

    const { ref, ...registerProps } = register(name, { onChange: (e) => onCustomChange?.(e.target.value) });

    useEffect(() => {
        const message = formState.errors[name]?.message || undefined;

        const isExpirationEmpty = formState.errors.expiry && formState.errors.month && formState.errors.year && registerProps.name === "expiry";
        const isExpirationIncorrect = formState.errors.expiry && (formState.errors.month || formState.errors.year) && registerProps.name === "expiry";

        if (isExpirationEmpty) {
            setError("Expiration date is required");
        } else if (isExpirationIncorrect) {
            setError("Invalid expiration date");
        } else if (typeof message === "string" || message === undefined) {
            setError(message);
            onErrorChange?.(message);
        }
    }, [formState.errors[name], onErrorChange, name]);

    return <TextField inputRef={ref} errorText={error} {...registerProps} {...props} disabled={formState.isSubmitting} />;
};
