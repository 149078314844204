export const getRecurringPaymentDates = (plan: { trialDuration: string }) => {
    if (!plan.trialDuration) {
        return { recurringPaymentEndDate: "", recurringPaymentStartDate: "" };
    }

    const now = new Date();
    let trialEndDate: Date;

    switch (plan.trialDuration) {
        case "1 day":
            trialEndDate = new Date(now.getTime() + 1 * 24 * 60 * 60 * 1000);
            break;
        case "7 days":
            trialEndDate = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
            break;
        case "30 days":
        case "1 mon":
            trialEndDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);
            break;
        case "3 mons":
        case "90 days":
            trialEndDate = new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000);
            break;
        case "91 days":
            trialEndDate = new Date(now.getTime() + 91 * 24 * 60 * 60 * 1000);
            break;
        default:
            trialEndDate = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
    }

    const recurringDate = trialEndDate.toISOString();

    return {
        recurringPaymentEndDate: recurringDate,
        recurringPaymentStartDate: recurringDate,
    };
};
