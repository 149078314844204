import { FC } from "react";
import { Box, DialogProps, Stack, Typography } from "@mui/material";
import { StyledButtonAccept, StyledButtonDecline, StyledDialog } from "../shared.styled";
import { useTranslation } from "hooks/useTranslation";
import { ExtraOffersType } from "../../ExtraOffers.types";
import { useOfferEvents } from "../../hooks";

interface Props extends DialogProps {
    onAccept?: () => void;
    onDecline?: () => void;
}

export const SecondaryOfferModal: FC<Props> = ({ open, onAccept, onDecline }) => {
    const { t } = useTranslation();
    const { handleAccept, handleDecline } = useOfferEvents({ offerName: ExtraOffersType.FOLLOW_UP, open });

    const handleModalAccept = () => handleAccept(onAccept);
    const handleModalDecline = () => handleDecline(onDecline);

    return (
        <StyledDialog
            open={open}
            onClose={handleModalDecline}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "24px",
                    width: "358px",
                    background: "linear-gradient(180deg, #202020 0%, #000 100%)",
                    boxShadow: "0px 0px 8px 0px rgba(255, 255, 255, 0.25)",
                },
            }}
        >
            <Stack>
                <Box sx={{ backgroundColor: "#454B6F", padding: "4px", textAlign: "center" }}>
                    <Typography variant="dmSansBody2Semibold" sx={{ fontSize: "16px", textTransform: "uppercase", color: "text.secondary" }}>
                        {t("wait! don't miss out")}
                    </Typography>
                </Box>
                <Stack
                    sx={{
                        padding: "12px 16px 36px",
                        textAlign: "center",
                    }}
                >
                    <Typography variant="dmSansBody2Semibold" sx={{ fontSize: "16px", color: "text.secondary", mb: "4px" }}>
                        {t("This exclusive deal disappears if you close this window.")}
                    </Typography>
                    <Typography variant="dmSansBody2Semibold" sx={{ mb: "16px", color: "text.disabled" }}>
                        {t("Grab your discount now before it's gone forever!")}
                    </Typography>
                    <StyledButtonAccept variant="themePrimarySmall" onClick={handleModalAccept} sx={{ mb: "10px" }}>
                        {t("Claim the Deal")}
                    </StyledButtonAccept>
                    <StyledButtonDecline variant="themePrimarySmall" onClick={handleModalDecline}>
                        {t("no thanks, I'll pass")}
                    </StyledButtonDecline>
                </Stack>
            </Stack>
        </StyledDialog>
    );
};
