import { PaymentPlanType } from "enums/payment.enum";

export const PAYMENT_PLAN_TYPE: PaymentPlanType = PaymentPlanType.SUBSCRIPTION_WITH_TRIAL;
export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const PROD_DOMAIN = process.env.REACT_APP_PROD_DOMAIN;
export const DEV_DOMAIN = process.env.REACT_APP_DEV_DOMAIN;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const ATTRIBUTION_URL = process.env.REACT_APP_ATTRIBUTION_URL;
export const IP_INFO_URL = process.env.REACT_APP_IP_INFO_URL;
export const IP_INFO_TOKEN = process.env.REACT_APP_IP_INFO_TOKEN;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || "";
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "";
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID || "";
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "";
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "";
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID || "";
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || "";

export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || "";
export const PAYPAL_PLAN_ID = process.env.REACT_APP_PAYPAL_PLAN_ID || "";
export const MAXPAY_KEY = process.env.REACT_APP_MAXPAY_KEY;
export const MAXPAY_SIGNATURE = process.env.REACT_APP_MAXPAY_SIGNATURE;
export const MAXPAY_PRODUCT_PUBLIC_ID = process.env.REACT_APP_MAXPAY_PRODUCT_PUBLIC_ID;

export const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME || "";
export const PAYMENT_SERVER_URL = process.env.REACT_APP_PAYMENT_SERVER_URL || "";

export const IXOPAY_URL = process.env.REACT_APP_IXOPAY_URL || "";
export const IXOPAY_INTEGRATION_KEY = process.env.REACT_APP_IXOPAY_INTEGRATION_KEY || "";

export const MAXPAY_ALT_KEY = process.env.REACT_APP_MAXPAY_ALT_KEY;
export const MAXPAY_ALT_SIGNATURE = process.env.REACT_APP_MAXPAY_ALT_SIGNATURE;
export const MAXPAY_ALT_PRODUCT_PUBLIC_ID = process.env.REACT_APP_MAXPAY_ALT_PRODUCT_PUBLIC_ID;

export const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || "";
export const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY || "";
