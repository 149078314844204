import { createContext, useContext } from "react";
import { useBoolean } from "hooks/useBoolean";

type SetBooleanType = ReturnType<typeof useBoolean>[1];

interface DevToolsContextType {
    isExpanded: boolean;
    setExpanded?: SetBooleanType;
    setDevToolsVisibility: SetBooleanType;
    isDevToolsShown: boolean;
}

export const DevToolsContext = createContext<DevToolsContextType | null>(null);

export const useDevTools = () => {
    const data = useContext(DevToolsContext);

    if (!data) {
        throw new Error("Can not useUser outside of the UserProvider");
    }

    return data;
};
